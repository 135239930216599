import HomePage from './views/HomePage.vue';
import PageNotFound from './views/PageNotFound.vue';
import B2b from './views/B2b.vue';
import LeadGen from './views/LeadGen.vue';
import Form from './views/Form.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
  },
  {
    path: '/blog/:slug',
    name: 'Post',
    component: HomePage,
  },
  {
    path: '/web_platforms/:slug',
    name: 'WebPlatform',
    component: HomePage,
  },
  {
    path: '/mobile_apps/:slug',
    name: 'MobileApp',
    component: HomePage,
  },
  {
    path: '/web_design/:slug',
    name: 'WebDesign',
    component: HomePage,
  },
  {
    path: '/branding/:slug',
    name: 'Branding',
    component: HomePage,
  },
  {
    path: '/b2b-saas-platforms-design',
    name: 'B2b',
    component: B2b,
  },
  {
    path: '/lead-gen',
    name: 'LeadGen',
    component: LeadGen,
  },
  {
    path: '/form',
    name: 'Form',
    component: Form,
  },
];

// var generateSitemap = require("vue-router-tiny-sitemap")
// generateSitemap(routes, "uvio.co")

export default routes;
