<template>
  <div id="app">
    <div :class="['post-page']">
      <div class="page-wrapper">
        <div class="page-content">
          <div class="typeform-embed" style="min-height: 100vh;" data-tf-widget="bkJKWwOh" data-tf-opacity="100" data-tf-inline-on-mobile data-tf-iframe-props="title=Uvio.co survey" data-tf-transitive-search-params data-tf-auto-focus data-tf-medium="snippet" data-tf-full-screen></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Form',
  data: function () {
    return {

    };
  },
  metaInfo: {
    title:
      'UVIO, Carefully devised digital products that strengthen your brand',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users',
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content:
          'UVIO, Carefully devised digital products that strengthen your brand',
      },
      {
        vmid: 'og:site_name',
        property: 'og:site_name',
        content:
          'UVIO, Carefully devised digital products that strengthen your brand',
      },
      {
        vmid: 'og:type',
        property: 'og:type',
        content: 'website',
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content:
          'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users',
      },
      {
        vmid: 'og:image',
        property: 'og:image',
        content: 'https://www.uvio.co/uvio/share-preview.jpg',
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: 'https://uvio.co',
      },
      {
        vmid: 'robots',
        name: 'robots',
        content: 'index,follow',
      },
      {
        vmid: 'twitter:card',
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        vmid: 'twitter:title',
        name: 'twitter:title',
        content:
          'UVIO, Carefully devised digital products that strengthen your brand',
      },
      {
        vmid: 'twitter:description',
        name: 'twitter:description',
        content:
          'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users',
      },
      {
        vmid: 'twitter:image',
        name: 'twitter:image',
        content: 'https://www.uvio.co/uvio/share-preview.jpg',
      },
      {
        vmid: 'twitter:image:alt',
        name: 'twitter:image:alt',
        content:
          'UVIO, Carefully devised digital products that strengthen your brand',
      },
    ],
  },
  created() {
    let script = document.createElement('script')
    script.src = 'https://embed.typeform.com/next/embed.js'
    document.body.appendChild(script)
  },
  watch: {},
  mounted: function () {},
  methods: {
  },
};
</script>
