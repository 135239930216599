var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"page post-page page-not-found"},[_c('div',{staticClass:"page-wrapper"},[_vm._m(0),_c('main',{staticClass:"main"},[_c('section',{staticClass:"dark-section"},[_c('div',{staticClass:"dark-section-wrapper"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/uvio/blue.png'),expression:"'/uvio/blue.png'"}],staticClass:"img blue",attrs:{"alt":"UVIO team"}}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/uvio/red.png'),expression:"'/uvio/red.png'"}],staticClass:"img red",attrs:{"alt":"UVIO team"}}),_vm._m(1)])])]),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer-bottom"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title-small"},[_vm._v("hello@uvio.co")]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"contact-btn-wrap"},[_c('a',{staticClass:"btn btn-whatsapp",attrs:{"href":"https://wa.me/971585154802","target":"_blank"}},[_c('span',{staticClass:"btn-icon"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                          require(`./../assets/socials/whatsapp.svg`)
                        ),expression:"\n                          require(`./../assets/socials/whatsapp.svg`)\n                        "}],staticClass:"whatsapp-icon",attrs:{"alt":"Whatsapp"}})]),_vm._m(4)]),_c('a',{staticClass:"btn btn-telegram",attrs:{"href":"/"}},[_c('span',{staticClass:"btn-icon"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                          require(`./../assets/socials/telegram.svg`)
                        ),expression:"\n                          require(`./../assets/socials/telegram.svg`)\n                        "}],staticClass:"telegram-icon",attrs:{"alt":"Whatsapp"}})]),_vm._m(5)])])]),_vm._m(6)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('div',{staticClass:"header-wrapper visible"},[_c('a',{staticClass:"header-logo",attrs:{"href":"https://www.uvio.co/"}},[_c('img',{staticClass:"img desk",attrs:{"alt":"UVIO","src":require("./../assets/post/logo.svg")}}),_c('img',{staticClass:"img mob",attrs:{"alt":"UVIO","src":require("./../assets/post/logoMob.svg")}})]),_c('div',{staticClass:"right right-fixed"},[_c('a',{staticClass:"button-book book-a-call-btn",attrs:{"href":"https://calendly.com/uvio-design/30min","target":"__blank","id":"book-a-call-btn","alt":"Book a call with UVIO"}},[_c('div',{staticClass:"button-book-wrapper"},[_c('span',[_vm._v("Book a Call")]),_c('img',{staticClass:"img",attrs:{"alt":"Calendar","src":require("./../assets/post/calendar.svg")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dark-section-inside"},[_c('div',{staticClass:"glitch-container"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"glitch",attrs:{"data-text":"4  0  4"}},[_vm._v("4 0 4")]),_c('div',{staticClass:"glow"},[_vm._v("4 0 4")])]),_c('div',{staticClass:"scanlines"})]),_c('div',{staticClass:"desc"},[_vm._v("You know what does it mean...")]),_c('a',{staticClass:"button",attrs:{"href":"https://www.uvio.co/"}},[_vm._v("HOME PAGE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('span',{staticClass:"gray"},[_vm._v("UAE Office:")]),_c('span',[_vm._v("146 DTEC, Dubai Silicon Oasis, Dubai , UAE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('span',{staticClass:"gray"},[_vm._v("US Office:")]),_c('span',[_vm._v("16192 Coastal Highway, Lewes, Delaware 19958 USA")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"btn-text"},[_vm._v("Start chat in "),_c('b',[_vm._v("Whatsapp")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"btn-text"},[_vm._v("Start chat in "),_c('b',[_vm._v("Telegram")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('ul',{staticClass:"list"},[_c('li',{staticClass:"item"},[_c('a',{staticClass:"link",attrs:{"href":"https://www.linkedin.com/company/uvio-design","target":"__blank"}},[_c('img',{staticClass:"img",attrs:{"src":require("./../assets/post/linkedIn.svg"),"alt":"LinkedIn"}})])]),_c('li',{staticClass:"item"},[_c('a',{staticClass:"link",attrs:{"href":"https://dribbble.com/uvio","target":"__blank"}},[_c('img',{staticClass:"img",attrs:{"src":require("./../assets/post/dribbble.svg"),"alt":"Dribble"}})])]),_c('li',{staticClass:"item"},[_c('a',{staticClass:"link",attrs:{"href":"https://www.behance.net/uvioagency","target":"__blank"}},[_c('img',{staticClass:"img",attrs:{"src":require("./../assets/post/behance.svg"),"alt":"Behance"}})])])]),_c('div',{staticClass:"desc"},[_vm._v("UVIO LLC © 2023")])])
}]

export { render, staticRenderFns }