import Vue from 'vue';

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import App from './App.vue';

import 'aos/dist/aos.css';
// import AOS from 'aos'
import Notifications from 'vue-notification';
import Vuelidate from 'vuelidate';
import VueLazyload from 'vue-lazyload';
import VueMeta from 'vue-meta';
// import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import VueRouter from 'vue-router';
import { Dialog } from 'element-ui';

import routes from './routes';

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);
import VueSocialSharing from 'vue-social-sharing';
Vue.use(VueSocialSharing);

// createApp(App).use(router).mount('#app')

import '../public/css/style.scss';

Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.use(VueLazyload, {
  lazyComponent: true,
});
Vue.use(VueMeta);
Vue.use(VueScrollTo);
Vue.use(Dialog);
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: routes,
});

new Vue({
  router,
  render: (h) => h(App),
  mounted() {
    // AOS.init()
  },
}).$mount('#app');

// createApp(App).use(router).mount('#app')
