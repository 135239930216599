<template>
  <div>
    <div data-tf-sidetab="bkJKWwOh" data-tf-opacity="100" data-tf-iframe-props="title=Uvio.co survey" data-tf-transitive-search-params data-tf-button-color="#FB0066" data-tf-button-text="Get 20% OFF" data-tf-medium="snippet" style="all:unset;"></div>
  </div>
</template>

<script>
export default {
  name: 'SideTab',
  data: () => ({
  }),
  methods: {
  },
  mounted: function () {
  },
  created() {
    let script = document.createElement('script')
    script.src = 'https://embed.typeform.com/next/embed.js'
    document.body.appendChild(script)
  },
};
</script>

<style></style>
