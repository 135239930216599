<template>
  <div class="share-modal">
    <div class="modal-form">
      <div class="close-button" @click="$emit('closeShareModal')"></div>
      <div class="modal-form-body">
        <div class="title-small">Share</div>
        <div class="share-list">
          <div class="link hover-link share-link desc">
            <ShareNetwork :title="title" network="linkedin" :url="fullPath()">
              <div class="img-container">
                <svg
                  fill="#430259"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 3333 3333"
                  shape-rendering="geometricPrecision"
                  text-rendering="geometricPrecision"
                  image-rendering="optimizeQuality"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                >
                  <path
                    d="M1465 1354h322v165h5c45-81 155-165 318-165 340 0 403 212 403 488v561h-336v-498c0-119-2-271-175-271-175 0-202 129-202 263v506h-336V1354zM1667 0c460 0 877 187 1179 488 302 302 488 718 488 1179 0 460-187 877-488 1179-302 302-718 488-1179 488-460 0-877-187-1179-488C186 2544 0 2128 0 1667c0-460 187-877 488-1179C790 186 1206 0 1667 0zm1093 573c-280-280-666-453-1093-453S853 293 574 573c-280 280-453 666-453 1093s173 814 453 1093c280 280 666 453 1093 453s814-173 1093-453c280-280 453-666 453-1093s-173-814-453-1093zm-1528 490c0 97-78 175-175 175s-175-78-175-175 78-175 175-175 175 78 175 175zm-350 291h350v1049H882V1354z"
                  />
                </svg>
              </div>
              LinkedIn
            </ShareNetwork>
          </div>
          <div class="link hover-link share-link desc">
            <ShareNetwork
              network="facebook"
              :url="fullPath()"
              :title="title"
              :description="description"
              hashtags="cuna, cuna real estate, real estate"
            >
              <div class="img-container">
                <svg
                  fill="#430259"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 3333 3333"
                  shape-rendering="geometricPrecision"
                  text-rendering="geometricPrecision"
                  image-rendering="optimizeQuality"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                >
                  <path
                    d="M1667 0c460 0 877 187 1179 488 302 302 488 718 488 1179 0 460-187 877-488 1179-302 302-718 488-1179 488-460 0-877-187-1179-488C186 2544 0 2128 0 1667c0-460 187-877 488-1179C790 186 1206 0 1667 0zm181 1137h301V776h-301c-232 0-421 189-421 421v181h-241v361h241v963h361v-963h301l60-361h-361v-181c0-33 28-60 60-60zm913-563c-280-280-666-453-1093-453S854 294 575 574c-280 280-453 666-453 1093s173 814 453 1093c280 280 666 453 1093 453s814-173 1093-453c280-280 453-666 453-1093s-173-814-453-1093z"
                    fill-rule="nonzero"
                  />
                </svg>
              </div>
              Facebook
            </ShareNetwork>
          </div>
          <div class="link hover-link share-link desc">
            <ShareNetwork
              network="twitter"
              :url="fullPath()"
              :title="title"
              :description="description"
              hashtags="cuna, cuna real estate, real estate"
            >
              <div class="img-container">
                <svg
                  fill="#430259"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 32 32"
                  enable-background="new 0 0 32 32"
                  xml:space="preserve"
                >
                  <g>
                    <path
                      d="M16-0.034C7.159-0.034-0.035,7.158-0.035,16S7.159,32.034,16,32.034S32.035,24.842,32.035,16
		S24.841-0.034,16-0.034z M16,30.966C7.748,30.966,1.035,24.252,1.035,16S7.748,1.034,16,1.034S30.965,7.748,30.965,16
		S24.252,30.966,16,30.966z"
                    />
                    <path
                      d="M24.794,11.046c0.11-0.204,0.202-0.419,0.273-0.643c0.067-0.212-0.002-0.442-0.176-0.581
		c-0.173-0.138-0.415-0.154-0.605-0.042c-0.513,0.305-1.065,0.53-1.646,0.673c-0.698-0.631-1.615-0.987-2.562-0.987
		c-2.105,0-3.817,1.712-3.817,3.815c0,0.053,0.001,0.105,0.003,0.158c-2.235-0.279-4.298-1.415-5.735-3.178
		c-0.11-0.136-0.28-0.207-0.456-0.195c-0.175,0.014-0.332,0.112-0.42,0.265c-0.338,0.58-0.517,1.243-0.517,1.919
		c0,0.64,0.159,1.256,0.451,1.802c-0.074,0.012-0.147,0.039-0.214,0.078c-0.163,0.097-0.264,0.292-0.264,0.481
		c0,1.135,0.505,2.177,1.319,2.885c-0.012,0.011-0.023,0.023-0.035,0.035c-0.125,0.142-0.166,0.338-0.108,0.518
		c0.351,1.094,1.164,1.946,2.184,2.365c-1.041,0.515-2.25,0.716-3.406,0.579c-0.244-0.022-0.481,0.116-0.566,0.352
		c-0.083,0.234,0.005,0.495,0.215,0.63c1.592,1.02,3.431,1.559,5.319,1.559c6.18,0,9.874-5.02,9.874-9.873
		c0-0.055,0-0.109-0.001-0.164c0.597-0.466,1.115-1.023,1.541-1.66c0.132-0.197,0.118-0.459-0.035-0.641
		C25.259,11.017,25.01,10.955,24.794,11.046z M23.049,12.801c-0.147,0.106-0.231,0.279-0.222,0.461
		c0.006,0.132,0.009,0.266,0.009,0.398c0,4.328-3.293,8.805-8.804,8.805c-0.989,0-1.964-0.166-2.887-0.488
		c1.105-0.211,2.141-0.682,3.045-1.39c0.178-0.14,0.249-0.376,0.177-0.591c-0.072-0.214-0.271-0.36-0.497-0.364
		c-0.891-0.018-1.699-0.461-2.194-1.162c0.253-0.017,0.501-0.059,0.742-0.124c0.239-0.065,0.401-0.286,0.393-0.534
		c-0.008-0.247-0.186-0.457-0.429-0.506c-1.003-0.201-1.79-0.943-2.081-1.905c0.266,0.067,0.539,0.106,0.812,0.115
		c0.235-0.001,0.453-0.145,0.526-0.371s-0.013-0.475-0.212-0.607c-0.765-0.512-1.222-1.366-1.222-2.287
		c0-0.223,0.027-0.443,0.079-0.658c1.731,1.775,4.08,2.847,6.568,2.972c0.176,0.001,0.33-0.062,0.437-0.191s0.148-0.3,0.11-0.464
		c-0.047-0.203-0.071-0.414-0.071-0.627c0-1.515,1.233-2.747,2.748-2.747c0.757,0,1.488,0.316,2.005,0.868
		c0.126,0.135,0.312,0.194,0.494,0.159c0.165-0.033,0.328-0.071,0.489-0.115c-0.072,0.054-0.147,0.104-0.226,0.151
		c-0.216,0.129-0.312,0.393-0.231,0.631s0.324,0.394,0.569,0.357c0.061-0.007,0.121-0.015,0.182-0.023
		C23.259,12.646,23.155,12.725,23.049,12.801z"
                    />
                  </g>
                </svg>
              </div>
              Twitter
            </ShareNetwork>
          </div>
          <div class="link hover-link share-link desc">
            <ShareNetwork
              network="whatsapp"
              :url="fullPath()"
              :title="title"
              :description="description"
              hashtags="cuna, cuna real estate, real estate"
            >
              <div class="img-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#430259"
                  viewBox="0 0 3333 3333"
                  shape-rendering="geometricPrecision"
                  text-rendering="geometricPrecision"
                  image-rendering="optimizeQuality"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                >
                  <path
                    d="M2341 978c-168-169-392-262-631-262-491 0-891 400-891 892 0 157 41 311 119 446l-127 462 473-124c130 71 277 109 426 109 491 0 892-400 892-892 0-238-93-462-261-631zM1666 0c460 0 877 187 1179 488 302 302 488 718 488 1179 0 460-187 877-488 1179-302 302-718 488-1179 488-460 0-877-187-1179-488C185 2544-1 2128-1 1667c0-460 187-877 488-1179C789 186 1205 0 1666 0zm1093 573c-280-280-666-453-1093-453S852 293 573 573c-280 280-453 666-453 1093s173 814 453 1093c280 280 666 453 1093 453s814-173 1093-453c280-280 453-666 453-1093s-173-814-453-1093zM1710 2350c-133 0-264-36-377-103l-27-16-281 73 75-273-18-28c-75-118-114-254-114-394 0-409 333-741 742-741 198 0 384 77 524 217s217 326 217 524c0 409-333 742-741 742zm406-555c-22-11-132-65-152-72s-35-11-50 11-58 72-71 87-26 17-48 6-94-35-179-111c-66-59-111-132-124-154s-1-34 10-45c10-10 22-26 33-39s15-22 22-37 4-28-2-39-50-121-69-166c-18-44-37-38-50-38-13-1-28-1-43-1s-39 6-59 28-78 76-78 186 80 215 91 231c11 15 157 240 381 336 53 23 95 37 127 47 53 17 102 14 140 9 43-6 132-54 151-106s19-97 13-106c-5-10-20-15-43-27z"
                  />
                </svg>
              </div>
              WhatsApp
            </ShareNetwork>
          </div>
          <div class="link hover-link share-link desc">
            <ShareNetwork
              network="email"
              :url="fullPath()"
              :title="title"
              :description="description"
              hashtags="cuna, cuna real estate, real estate"
            >
              <div class="img-container">
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 64 64"
                  style="enable-background: new 0 0 64 64"
                  xml:space="preserve"
                  fill="#430259"
                >
                  <g>
                    <g>
                      <g id="circle_4_">
                        <g>
                          <path
                            d="M32,0C14.327,0,0,14.327,0,32s14.327,32,32,32s32-14.327,32-32S49.673,0,32,0z M32,62C15.432,62,2,48.568,2,32.001
                                C2,15.432,15.432,2,32,2c16.568,0,30,13.432,30,30.001C62,48.568,48.568,62,32,62z"
                          />
                        </g>
                      </g>
                      <g id="mail">
                        <g>
                          <path
                            d="M46,21H18c-1.104,0-2,0.896-2,2v18c0,1.104,0.896,2,2,2h28c1.104,0,2-0.896,2-2V23C48,21.896,47.104,21,46,21z
                                M44.205,23L32,35L19.891,23H44.205z M18,41V24.006l9.589,9.589L20.061,41H18z M22.985,41l6.081-5.927l1.58,1.579
                                c0.781,0.781,2.047,0.781,2.829,0l1.531-1.531L41.087,41H22.985z M46,41h-1.939l-7.527-7.405L46,24.128V41z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
              Email
            </ShareNetwork>
          </div>
        </div>
        <div class="copy-block">
          <div class="title-small">Copy link</div>
          <div class="url">
            <div class="desc">{{ fullPath() }}</div>
            <div
              class="copy-button"
              v-clipboard:copy="fullPath()"
              v-clipboard:success="onCopy"
            >
              <img
                alt="Share UVIO"
                class="img"
                src="./../../assets/post/copy-icon.svg"
              />
            </div>
          </div>
          <transition name="fade">
            <div v-if="copyMessageVisible" class="copy-success desc">
              Link copied to clipboard!
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ShareModal',
  props: ['title', 'description'],
  components: {},
  created() {},
  data() {
    return {
      copyMessageVisible: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    onCopy() {
      let self = this;
      self.copyMessageVisible = true;
      setTimeout(function () {
        self.copyMessageVisible = false;
      }, 2000);
    },
    fullPath() {
      return window.location.href;
    },
  },
  filters: {},
};
</script>
<style lang="scss"></style>
