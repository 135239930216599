<template>
  <div id="app" :class="[{ isSafariOld: isSafariOld }, { safari: isSafari }]">
    <custom-header v-if="visibleButton" :isHome="true"></custom-header>
    <transition name="fade">
      <div class="roller-container" v-show="!pageLoaded">
        <div class="uvio-loader">
          <img
            class="img logo-sign"
            alt="UVIO"
            src="./../assets/logo-sign.png"
          />
          <img class="img logo-red" alt="UVIO" src="./../assets/logo-red.png" />
          <img
            class="img logo-blue"
            alt="UVIO"
            src="./../assets/logo-blue.png"
          />
        </div>
      </div>
    </transition>
    <div
      :class="['roller-container', { active: scrollLoader }]"
      v-if="scrollLoader"
    ></div>
    <div
      :class="['roller-container', { workActive: scrollLoaderWork }]"
      v-if="scrollLoaderWork"
    ></div>
    <div class="page-scroll" id="smoothContent">
      <div class="page" id="page" v-if="loadedImages == 2">
        <div class="page-wrapper">
          <header :class="[{ active: scrollPosition > 10 }, 'header']">
            <div :class="['header-wrapper', { visible: visibleButton }]">
              <div
                :class="['header-logo', { white: darkBgVisible }]"
                v-scroll-to="{ el: '.page', duration: 1500 }"
              >
                <img class="img desk" alt="UVIO" v-lazy="'/uvio/logo.svg'" />
                <img class="img mob" alt="UVIO" v-lazy="'/uvio/logoMob.svg'" />
              </div>
            </div>
          </header>
          <main class="main">
            <section class="hero">
              <div :class="['subtitle', { active: visibleButton2 }]">
                <img
                  alt="Rate icon"
                  class="img"
                  src="./../assets/rate-icon.svg"
                />
                <span><b>4.9</b></span>
                <span>Rated Creative Agency</span>
              </div>
              <h1
                :class="[
                  'title',
                  { active: visibleTitle },
                  { visible: visibleHeroTitle },
                ]"
              >
                <span class="u-cont">
                  <span class="u-0">Carefully Devised Digital </span>
                  <span class="u-1">Products that Strengthen </span>
                </span>
                <span class="u-cont">
                  <span class="u-2">Brands</span>
                </span>
              </h1>
              <h1
                :class="[
                  'title mob',
                  { active: visibleTitle },
                  { visible: visibleHeroTitle },
                ]"
              >
                <span class="u-cont">
                  <span class="u-0">Carefully Devised </span>
                  <span class="u-1">Digital Products that </span>
                </span>
                <span class="u-cont">
                  <span class="u-2">Strengthen Brands</span>
                </span>
              </h1>
              <div :class="['button-container', { active: visibleButton2 }]">
                <button
                  class="button"
                  id="our-works-main-btn"
                  ref="ourWorksBtn"
                  @click="ourWorksScroll"
                >
                  <div id="circle" class="circle-container">
                    <img
                      class="circle img"
                      alt="UVIO works"
                      v-lazy="'/uvio/circle.png'"
                    />
                  </div>
                  <span>OUR WORKS</span>
                </button>
              </div>
            </section>
            <section id="features" :class="['section-1']">
              <div class="section-1-wrapper">
                <div>
                  <p class="desc">
                    Specializing in business consulting services. We offer
                    expertise in the field of it, developing and testing new
                    business models, creating financial metrics & targets,
                    monitoring key business metrics, and integrating data
                    analytics services. Our goal is to assist companies in
                    multiplying their revenue several times over in a short span
                    of time.
                  </p>
                </div>
                <div>
                  <div class="features-row-1">
                    <div class="feature-wrap">
                      <h3 class="title">
                        <div>x2.5 TIMES</div>
                      </h3>
                      <p class="desc">
                        After just two months of working with us, companies
                        experience a 2.5-fold increase in revenue. This is
                        achieved through the implementation of analytics, its
                        decoding, and scaling up sales channels
                      </p>
                    </div>
                    <div class="feature-wrap">
                      <h3 class="title">
                        <div>x13 TIMES</div>
                      </h3>
                      <p class="desc">
                        In just 4.5 months, in our best case, we boosted a
                        company's profit by 13 times. This was made possible
                        through cost optimization and the use of alternative
                        traffic sources
                      </p>
                    </div>
                    <div class="feature-wrap">
                      <h3 class="title">
                        <div>UP TO 70%</div>
                      </h3>
                      <p class="desc">
                        We automate up to 70% of manual labor in businesses,
                        allowing employees to focus on the most crucial aspects
                        of their work. This not only enhances their productivity
                        but also makes the business more efficient
                      </p>
                    </div>
                    <div class="feature-wrap 50per">
                      <h3 class="title">
                        <div>25 DAYS</div>
                      </h3>
                      <p class="desc">
                        On average, it takes us 25 working days to elevate a
                        company to the next level and initiate its scalin
                      </p>
                    </div>
                    <div class="feature-wrap 50per">
                      <h3 class="title">
                        <div>50+ CLIENTS</div>
                      </h3>
                      <p class="desc">
                        Over 50 clients from around the world are delighted to
                        work with us.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              id="about"
              :class="['section-2', { visible: visibleButton4 }]"
            >
              <div class="section-2-wrapper">
                <div class="text-container">
                  <div class="text" id="text">
                    <div class="text-wrapper text-wrapper-1">
                      <h2 class="title-small">About <b>UVIO</b></h2>
                      <h3 class="title">
                        <div>Understanding</div>
                        <span>your pain points</span>
                      </h3>
                      <p class="desc">
                        In an ever-evolving IT sphere, UVIO well understands the
                        demand for clean, contemporary and convenient designs.
                        <br /><br />UVIO’s team of seasoned UX and UI experts,
                        led by designers and co-founders who were Mobile UX
                        Awards judges, have been building digital experiences
                        together since 2016.
                      </p>
                    </div>
                    <div class="text-wrapper text-wrapper-2">
                      <h3 class="title">
                        <div>Visioning</div>
                        <span>your solutions</span>
                      </h3>
                      <p class="desc">
                        UVIO’s multidisciplinary team of senior UX and UI
                        designers, developers and other specialists quickly
                        craft user interfaces and experiences that sacrifice
                        neither facade nor function. <br /><br />We build to
                        both aesthetically please and perform with your brand’s
                        story stitched into the very fabric of our work.
                      </p>
                    </div>
                    <div class="text-wrapper text-wrapper-last text-wrapper-3">
                      <h3 class="title">
                        <div>Implementing</div>
                        <span>innovative designs</span>
                      </h3>
                      <p class="desc">
                        We don’t skip a beat. UVIO combines creative and product
                        design capabilities under one roof. <br /><br />And we
                        do it all with the end-user perspective and edge-case
                        scenarios in mind to create comprehensive digital
                        experiences.
                      </p>
                    </div>
                    <div class="text-wrapper text-wrapper-4">
                      <h3 class="title">
                        <div>Offering</div>
                        <span>an outcome</span>
                      </h3>
                      <p class="desc">
                        Maintaining consistent communication and total
                        transparency, we deliver results that exceed
                        expectations for our clients across industries and the
                        world. <br /><br />We’ve launched more than 100 projects
                        since our onset. And we can promise you easy-to-navigate
                        digital products positioned for the people who use them,
                        too.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="img-wrapper">
                  <div class="img-container fixedImg">
                    <img v-lazy="'/uvio/uvio.jpg'" alt="UVIO" class="img" />
                  </div>
                </div>
              </div>
            </section>
            <section class="partners" data-aos="fade-us2">
              <h2 class="title-small">Trusted by</h2>
              <ul class="list">
                <li class="item">
                  <div :class="['item-wrapper', { active: logosReverse }]">
                    <transition name="fade2">
                      <img
                        v-show="logosReverse == 1"
                        class="img"
                        alt="UVIO"
                        src="./../assets/w-logo/1.svg"
                      />
                    </transition>
                    <transition name="fade2">
                      <img
                        v-show="logosReverse == 2"
                        class="img"
                        alt="UVIO"
                        src="./../assets/w-logo/2.svg"
                      />
                    </transition>
                  </div>
                </li>
                <li class="item">
                  <div :class="['item-wrapper', { active: logosReverse2 }]">
                    <transition name="fade2">
                      <img
                        v-show="logosReverse2 == 1"
                        class="img"
                        alt="UVIO"
                        src="./../assets/w-logo/3.svg"
                      />
                    </transition>
                    <transition name="fade2">
                      <img
                        v-show="logosReverse2 == 2"
                        class="img"
                        alt="UVIO"
                        src="./../assets/w-logo/4.svg"
                      />
                    </transition>
                  </div>
                </li>
                <li class="item">
                  <div :class="['item-wrapper', { active: logosReverse3 }]">
                    <transition name="fade2">
                      <img
                        v-show="logosReverse3 == 1"
                        class="img"
                        alt="UVIO"
                        src="./../assets/w-logo/5.svg"
                      />
                    </transition>
                    <transition name="fade2">
                      <img
                        v-show="logosReverse3 == 2"
                        class="img"
                        alt="UVIO"
                        src="./../assets/w-logo/6.svg"
                      />
                    </transition>
                  </div>
                </li>
                <li class="item">
                  <div :class="['item-wrapper', { active: logosReverse4 }]">
                    <transition name="fade2">
                      <img
                        v-show="logosReverse4 == 1"
                        class="img"
                        alt="UVIO"
                        src="./../assets/w-logo/7.svg"
                      />
                    </transition>
                    <transition name="fade2">
                      <img
                        v-show="logosReverse4 == 2"
                        class="img"
                        alt="UVIO"
                        src="./../assets/w-logo/8.svg"
                      />
                    </transition>
                    <transition name="fade2">
                      <img
                        v-show="logosReverse4 == 3"
                        class="img"
                        alt="UVIO"
                        src="./../assets/w-logo/9.svg"
                      />
                    </transition>
                  </div>
                </li>
              </ul>
            </section>
            <section
              id="expertise"
              class="dark-section"
              ref="darkBg"
              data-aos="fade-us"
            >
              <div class="dark-section-wrapper" id="darkSection">
                <img
                  class="img blue"
                  alt="UVIO team"
                  v-lazy="'/uvio/blue.png'"
                />
                <img class="img red" alt="UVIO team" v-lazy="'/uvio/red.png'" />
                <div class="title title-main">Services</div>
                <div class="dark-section-inside">
                  <div class="left">
                    <div class="expertise-switch" ref="expertiseSwitch"></div>
                    <ul class="list" id="expertiseList">
                      <li
                        :class="[
                          'item dark-section-inside-item',
                          { active: item.title == activeExpertiseChosen },
                        ]"
                        v-for="(item, i) in expertise"
                        :key="i"
                        @click="setExpertise(item)"
                      >
                        {{ item.title }}
                      </li>
                    </ul>
                  </div>
                  <div class="right">
                    <div
                      v-if="activeExpertise"
                      :class="[
                        'title-small',
                        { active: expertiseAnimationStart },
                      ]"
                    >
                      {{ activeExpertise.title }}
                    </div>
                    <div
                      v-if="activeExpertise"
                      :class="['desc', { active: expertiseAnimationMiddle }]"
                    >
                      {{ activeExpertise.desc }}
                    </div>
                    <div
                      v-if="activeExpertise.images"
                      :class="['icon-list', { active: expertiseAnimationEnd }]"
                    >
                      <div
                        class="icon_item"
                        v-for="item in activeExpertise.images"
                        :key="item"
                      >
                        <img
                          v-if="item"
                          class="img"
                          :alt="item"
                          v-lazy="
                            require(`./../assets/technologies/${activeExpertise.title.toLowerCase()}/${item}.png`)
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div class="slider-dark-container">
                    <slick ref="slick" :options="slickOptions" class="carousel">
                      <div
                        class="sliderDark__item"
                        v-for="(item, i) in expertise"
                        :key="i"
                      >
                        <div class="item active">{{ item.title }}</div>
                        <div class="desc active">{{ item.desc }}</div>
                        <div class="icon-list active" v-if="item.images">
                          <div
                            class="icon_item"
                            v-for="itt in item.images"
                            :key="itt"
                          >
                            <img
                              v-if="itt"
                              class="img"
                              :alt="itt"
                              v-lazy="
                                require(`./../assets/technologies/${item.title.toLowerCase()}/${itt}.png`)
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </slick>
                  </div>
                </div>
              </div>
            </section>
            <section
              id="superpower"
              class="superpower"
              ref="Superpowers"
              data-aos="fade-us2"
            >
              <div class="superpower-wrapper">
                <div class="title title-main">Superpowers</div>
                <div class="list">
                  <div class="item super-1">
                    <svg
                      width="120"
                      height="120"
                      viewBox="0 0 120 120"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        class="path1"
                        opacity="0.1"
                        cx="60"
                        cy="60"
                        r="58.5"
                        stroke="#B5A6F9"
                        stroke-width="3"
                      />
                      <circle
                        class="path2"
                        opacity="0.5"
                        cx="59.9999"
                        cy="60.0002"
                        r="42.2143"
                        stroke="#B5A6F9"
                        stroke-width="3"
                      />
                      <circle
                        class="path3"
                        cx="60.4283"
                        cy="49.2857"
                        r="11.7857"
                        stroke="url(#paint0_linear_4784_38807)"
                        stroke-width="3"
                      />
                      <path
                        class="path4"
                        d="M79.714 81.4286C79.714 73.8544 71.0795 67.7143 60.4283 67.7143C49.7771 67.7143 41.1426 73.8544 41.1426 81.4286"
                        stroke="url(#paint1_linear_4784_38807)"
                        stroke-width="3"
                        stroke-linecap="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_4784_38807"
                          x1="55.2428"
                          y1="31.5714"
                          x2="35.5542"
                          y2="49.3084"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2901E6" />
                          <stop offset="1" stop-color="#FB0066" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_4784_38807"
                          x1="52.901"
                          y1="65.4286"
                          x2="45.9229"
                          y2="83.1091"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2901E6" />
                          <stop offset="1" stop-color="#FB0066" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <div class="text">
                      <div class="title">Human-centered design</div>
                      <div class="desc">
                        Focusing on real people's needs and business
                        requirements enables us to deliver products and services
                        that resonate with users' experiences and emotions.
                      </div>
                    </div>
                  </div>
                  <div class="item super-2">
                    <svg
                      viewBox="0 0 100 102"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        class="path1"
                        cx="50"
                        cy="58"
                        r="42.5"
                        stroke="#B5A6F9"
                        stroke-width="3"
                      />
                      <circle
                        class="path2"
                        cx="84"
                        cy="83"
                        r="14.5"
                        stroke="url(#paint0_linear_4896_25025)"
                        stroke-width="3"
                      />
                      <circle
                        class="path3"
                        cx="16"
                        cy="83"
                        r="14.5"
                        stroke="url(#paint1_linear_4896_25025)"
                        stroke-width="3"
                      />
                      <circle
                        class="path4"
                        cx="50"
                        cy="16"
                        r="14.5"
                        stroke="url(#paint2_linear_4896_25025)"
                        stroke-width="3"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_4896_25025"
                          x1="77.7551"
                          y1="61.6667"
                          x2="54.0441"
                          y2="83.0273"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2901E6" />
                          <stop offset="1" stop-color="#FB0066" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_4896_25025"
                          x1="9.7551"
                          y1="61.6667"
                          x2="-13.9559"
                          y2="83.0273"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2901E6" />
                          <stop offset="1" stop-color="#FB0066" />
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear_4896_25025"
                          x1="43.7551"
                          y1="-5.33333"
                          x2="20.0441"
                          y2="16.0273"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2901E6" />
                          <stop offset="1" stop-color="#FB0066" />
                        </linearGradient>
                      </defs>
                    </svg>

                    <div class="text">
                      <div class="title">Full cycle company</div>
                      <div class="desc">
                        Our clients get complete solutions from planning and
                        ideation to post-launch services. Everything is
                        included.
                      </div>
                    </div>
                  </div>
                  <div class="item super-3">
                    <svg
                      width="120"
                      height="120"
                      viewBox="0 0 120 120"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        class="path1"
                        opacity="0.1"
                        cx="60"
                        cy="60"
                        r="58.5"
                        stroke="#B5A6F9"
                        stroke-width="3"
                      />
                      <circle
                        class="path2"
                        opacity="0.5"
                        cx="59.9999"
                        cy="69.4286"
                        r="42.2143"
                        stroke="#B5A6F9"
                        stroke-width="3"
                      />
                      <circle
                        class="path3"
                        cx="60.4286"
                        cy="81"
                        r="22.9286"
                        stroke="url(#paint0_linear_4774_31179)"
                        stroke-width="3"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_4774_31179"
                          x1="50.894"
                          y1="48.4286"
                          x2="14.6923"
                          y2="81.0417"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2901E6" />
                          <stop offset="1" stop-color="#FB0066" />
                        </linearGradient>
                      </defs>
                    </svg>

                    <div class="text">
                      <div class="title">Creative Professionals that care</div>
                      <div class="desc">
                        UVIO’s creative processes bring graceful products.
                        Graceful products bring happy users. Happy users bring
                        business growth. Business growth brings happy business
                        owners. Just as simple as that!
                      </div>
                    </div>
                  </div>
                  <div class="item super-4">
                    <svg
                      width="120"
                      height="120"
                      viewBox="0 0 120 120"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        class="path1"
                        opacity="0.5"
                        x="1.5"
                        y="1.5"
                        width="117"
                        height="117"
                        rx="30.5"
                        stroke="#B5A6F9"
                        stroke-width="3"
                      />
                      <circle
                        class="path2"
                        cx="32.6656"
                        cy="32.6657"
                        r="15.7371"
                        stroke="url(#paint0_linear_4774_31205)"
                        stroke-width="3"
                      />
                      <circle
                        class="path3"
                        cx="86.6654"
                        cy="32.6657"
                        r="15.7371"
                        stroke="url(#paint2_linear_4774_31205)"
                        stroke-width="3"
                      />
                      <circle
                        class="path4"
                        cx="86.6654"
                        cy="86.6657"
                        r="15.7371"
                        stroke="url(#paint3_linear_4774_31205)"
                        stroke-width="3"
                      />
                      <circle
                        class="path5"
                        cx="32.6654"
                        cy="86.6657"
                        r="15.7371"
                        stroke="url(#paint1_linear_4774_31205)"
                        stroke-width="3"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_4774_31205"
                          x1="25.9378"
                          y1="9.68288"
                          x2="0.393443"
                          y2="32.6952"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2901E6" />
                          <stop offset="1" stop-color="#FB0066" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_4774_31205"
                          x1="25.9376"
                          y1="63.6829"
                          x2="0.393199"
                          y2="86.6952"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2901E6" />
                          <stop offset="1" stop-color="#FB0066" />
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear_4774_31205"
                          x1="79.9376"
                          y1="9.68288"
                          x2="54.3932"
                          y2="32.6952"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2901E6" />
                          <stop offset="1" stop-color="#FB0066" />
                        </linearGradient>
                        <linearGradient
                          id="paint3_linear_4774_31205"
                          x1="79.9376"
                          y1="63.6829"
                          x2="54.3932"
                          y2="86.6952"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2901E6" />
                          <stop offset="1" stop-color="#FB0066" />
                        </linearGradient>
                      </defs>
                    </svg>

                    <div class="text">
                      <div class="title">Clear and Timely Communication</div>
                      <div class="desc">
                        The client’s time is very important. That’s why working
                        with UVIO, the client always stays up to date through
                        daily reports and direct communication.
                      </div>
                    </div>
                  </div>
                  <div class="item super-5">
                    <svg
                      width="120"
                      height="120"
                      viewBox="0 0 120 120"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        class="path2"
                        cx="78"
                        cy="44"
                        r="34.5"
                        stroke="#B5A6F9"
                        stroke-width="3"
                      />
                      <circle
                        class="path3"
                        opacity="0.5"
                        cx="78"
                        cy="76"
                        r="34.5"
                        stroke="#B5A6F9"
                        stroke-width="3"
                      />
                      <circle
                        class="path1"
                        cx="42"
                        cy="44"
                        r="34.5"
                        stroke="url(#paint0_linear_4774_31212)"
                        stroke-width="3"
                      />
                      <circle
                        class="path4"
                        opacity="0.2"
                        cx="42"
                        cy="76"
                        r="34.5"
                        stroke="#B5A6F9"
                        stroke-width="3"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_4774_31212"
                          x1="27.949"
                          y1="-3.99999"
                          x2="-25.4008"
                          y2="44.0615"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2901E6" />
                          <stop offset="1" stop-color="#FB0066" />
                        </linearGradient>
                      </defs>
                    </svg>

                    <div class="text">
                      <div class="title">
                        Addressing edge-cases in every project
                      </div>
                      <div class="desc">
                        UVIO team members are forward thinkers and problem
                        solvers that deliver solutions for boundary conditions
                        in every single project. These methods allow developing
                        the products that will work in real life for real users.
                      </div>
                    </div>
                  </div>
                  <div class="item super-6">
                    <svg
                      width="120"
                      height="120"
                      viewBox="0 0 120 120"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        class="path2"
                        opacity="0.5"
                        x="2.29556"
                        y="1.27165"
                        width="84.723"
                        height="57.8574"
                        rx="18.5"
                        transform="matrix(1 0 0.530371 0.847766 -0.674445 34.3974)"
                        stroke="#B5A6F9"
                        stroke-width="3"
                      />
                      <rect
                        class="path1"
                        x="2.29556"
                        y="1.27165"
                        width="84.723"
                        height="57.8574"
                        rx="18.5"
                        transform="matrix(1 0 0.530371 0.847766 -0.674445 3.19359)"
                        stroke="url(#paint0_linear_4784_38823)"
                        stroke-width="3"
                      />
                      <rect
                        class="path3"
                        opacity="0.2"
                        x="2.29556"
                        y="1.27165"
                        width="84.723"
                        height="57.8574"
                        rx="18.5"
                        transform="matrix(1 0 0.530371 0.847766 -0.674445 65.6011)"
                        stroke="#B5A6F9"
                        stroke-width="3"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_4784_38823"
                          x1="26.7421"
                          y1="-10.1429"
                          x2="-17.0928"
                          y2="46.7796"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2901E6" />
                          <stop offset="1" stop-color="#FB0066" />
                        </linearGradient>
                      </defs>
                    </svg>

                    <div class="text">
                      <div class="title">Leveraging smart design systems</div>
                      <div class="desc">
                        We create smart design systems that help to design and
                        iterate with lightning speed. As a result, it saves
                        clients time and money and can be used by the client's
                        internal design team later.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="works" class="works" ref="works" data-aos="fade-us2">
              <div :class="['works-wrapper', { loaded: moreWorks }]">
                <div class="title">Our works</div>
                <ul class="tag-list">
                  <li
                    :class="[
                      'tag-list__item',
                      { active: item == activeCategory },
                    ]"
                    v-for="(item, i) in tags"
                    :key="i"
                    @click="filterWorksByTag(item)"
                  >
                    {{ item }}
                  </li>
                </ul>
                <ul
                  :class="['work-list', { active: activeTag == 'Web Design' }]"
                >
                  <li
                    :class="[
                      'work-list__item',
                      { visible: i < 4 },
                      item.folderName,
                    ]"
                    v-for="(item, i) in webDesignWorks"
                    :key="i"
                    @click="openWorkModal(item)"
                  >
                    <transition
                      :name="mobileScreen ? item.animationMob : item.animation"
                    >
                      <div
                        :class="['img-container']"
                        v-show="activeTag == 'Web Design'"
                        :style="{ backgroundColor: '#' + item.color }"
                      >
                        <img
                          class="img"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/0.png'"
                        />
                        <img
                          class="img mob"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/00.jpg'"
                        />
                        <div class="work-hover-section">
                          <div class="hover-tag desc">{{ item.tag }}</div>
                          <h3 class="hover-title desc">{{ item.title }}</h3>
                          <p class="hover-desc desc">{{ item.desc }}</p>
                          <div class="hover-link desc mob">View case</div>
                        </div>
                      </div>
                    </transition>
                  </li>
                </ul>
                <ul
                  :class="['work-list', { active: activeTag == 'Web Design' }]"
                >
                  <li
                    :class="[
                      'work-list__item',
                      { visible: i > 3 && moreWorks },
                      item.folderName,
                    ]"
                    v-for="(item, i) in webDesignWorks"
                    :key="i"
                    @click="openWorkModal(item)"
                  >
                    <transition
                      :name="mobileScreen ? item.animationMob : item.animation"
                    >
                      <div
                        :class="['img-container']"
                        v-show="activeTag == 'Web Design'"
                        :style="{ backgroundColor: '#' + item.color }"
                      >
                        <img
                          class="img"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/0.png'"
                        />
                        <img
                          class="img mob"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/00.jpg'"
                        />
                        <div class="work-hover-section">
                          <div class="hover-tag desc">{{ item.tag }}</div>
                          <h3 class="hover-title desc">{{ item.title }}</h3>
                          <p class="hover-desc desc">{{ item.desc }}</p>
                          <div class="hover-link desc mob">View case</div>
                        </div>
                      </div>
                    </transition>
                  </li>
                </ul>
                <ul
                  :class="['work-list', { active: activeTag == 'Mobile Apps' }]"
                >
                  <li
                    :class="[
                      'work-list__item',
                      { visible: i < 4 },
                      item.folderName,
                    ]"
                    v-for="(item, i) in mobileAppsWorks"
                    :key="i"
                    @click="openWorkModal(item)"
                  >
                    <transition
                      :name="mobileScreen ? item.animationMob : item.animation"
                    >
                      <div
                        :class="['img-container']"
                        v-show="activeTag == 'Mobile Apps'"
                        :style="{ backgroundColor: '#' + item.color }"
                      >
                        <img
                          class="img"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/0.png'"
                        />
                        <img
                          class="img mob"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/00.jpg'"
                        />
                        <div class="work-hover-section">
                          <div class="hover-tag desc">{{ item.tag }}</div>
                          <h3 class="hover-title desc">{{ item.title }}</h3>
                          <p class="hover-desc desc">{{ item.desc }}</p>
                          <div class="hover-link desc mob">View case</div>
                        </div>
                      </div>
                    </transition>
                  </li>
                </ul>
                <ul
                  :class="['work-list', { active: activeTag == 'Mobile Apps' }]"
                >
                  <li
                    :class="[
                      'work-list__item',
                      { visible: i > 3 && moreWorks },
                      item.folderName,
                    ]"
                    v-for="(item, i) in mobileAppsWorks"
                    :key="i"
                    @click="openWorkModal(item)"
                  >
                    <transition
                      :name="mobileScreen ? item.animationMob : item.animation"
                    >
                      <div
                        :class="['img-container']"
                        v-show="activeTag == 'Mobile Apps'"
                        :style="{ backgroundColor: '#' + item.color }"
                      >
                        <img
                          class="img"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/0.png'"
                        />
                        <img
                          class="img mob"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/00.jpg'"
                        />
                        <div class="work-hover-section">
                          <div class="hover-tag desc">{{ item.tag }}</div>
                          <h3 class="hover-title desc">{{ item.title }}</h3>
                          <p class="hover-desc desc">{{ item.desc }}</p>
                          <div class="hover-link desc mob">View case</div>
                        </div>
                      </div>
                    </transition>
                  </li>
                </ul>
                <ul
                  :class="[
                    'work-list',
                    { active: activeTag == 'Web Platforms' },
                  ]"
                >
                  <li
                    :class="[
                      'work-list__item',
                      { visible: i < 4 },
                      item.folderName,
                    ]"
                    v-for="(item, i) in webPlatformsWorks"
                    :key="i"
                    @click="openWorkModal(item)"
                  >
                    <transition
                      :name="mobileScreen ? item.animationMob : item.animation"
                    >
                      <div
                        :class="['img-container']"
                        v-show="activeTag == 'Web Platforms'"
                        :style="{ backgroundColor: '#' + item.color }"
                      >
                        <img
                          class="img"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/0.png'"
                        />
                        <img
                          class="img mob"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/00.jpg'"
                        />
                        <div class="work-hover-section">
                          <div class="hover-tag desc">{{ item.tag }}</div>
                          <h3 class="hover-title desc">{{ item.title }}</h3>
                          <p class="hover-desc desc">{{ item.desc }}</p>
                          <div class="hover-link desc mob">View case</div>
                        </div>
                      </div>
                    </transition>
                  </li>
                </ul>
                <ul
                  :class="[
                    'work-list',
                    { active: activeTag == 'Web Platforms' },
                  ]"
                >
                  <li
                    :class="[
                      'work-list__item',
                      { visible: i > 3 && moreWorks },
                      item.folderName,
                    ]"
                    v-for="(item, i) in webPlatformsWorks"
                    :key="i"
                    @click="openWorkModal(item)"
                  >
                    <transition
                      :name="mobileScreen ? item.animationMob : item.animation"
                    >
                      <div
                        :class="['img-container']"
                        v-show="activeTag == 'Web Platforms'"
                        :style="{ backgroundColor: '#' + item.color }"
                      >
                        <img
                          class="img"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/0.png'"
                        />
                        <img
                          class="img mob"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/00.jpg'"
                        />
                        <div class="work-hover-section">
                          <div class="hover-tag desc">{{ item.tag }}</div>
                          <h3 class="hover-title desc">{{ item.title }}</h3>
                          <p class="hover-desc desc">{{ item.desc }}</p>
                          <div class="hover-link desc mob">View case</div>
                        </div>
                      </div>
                    </transition>
                  </li>
                </ul>
                <ul
                  :class="[
                    'work-list work-list-branding',
                    { active: activeTag == 'Branding' },
                  ]"
                >
                  <li
                    :class="[
                      'work-list__item',
                      { visible: i < 4 },
                      item.folderName,
                    ]"
                    v-for="(item, i) in brandingWorks"
                    :key="i"
                    @click="openWorkModal(item)"
                  >
                    <transition
                      :name="mobileScreen ? item.animationMob : item.animation"
                    >
                      <div
                        :class="['img-container']"
                        v-show="activeTag == 'Branding'"
                        :style="{ backgroundColor: '#' + item.color }"
                      >
                        <img
                          class="img"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/0.png'"
                        />
                        <img
                          class="img mob"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/00.jpg'"
                        />
                        <div class="work-hover-section">
                          <div class="hover-tag desc">{{ item.tag }}</div>
                          <h3 class="hover-title desc">{{ item.title }}</h3>
                          <p class="hover-desc desc">{{ item.desc }}</p>
                          <div class="hover-link desc mob">View case</div>
                        </div>
                      </div>
                    </transition>
                  </li>
                </ul>
                <ul
                  :class="[
                    'work-list work-list-branding',
                    { active: activeTag == 'Branding' },
                  ]"
                >
                  <li
                    :class="[
                      'work-list__item',
                      { visible: i > 3 && moreWorks },
                      item.folderName,
                    ]"
                    v-for="(item, i) in brandingWorks"
                    :key="i"
                    @click="openWorkModal(item)"
                  >
                    <transition
                      :name="mobileScreen ? item.animationMob : item.animation"
                    >
                      <div
                        :class="['img-container']"
                        v-show="activeTag == 'Branding'"
                        :style="{ backgroundColor: '#' + item.color }"
                      >
                        <img
                          class="img"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/0.png'"
                        />
                        <img
                          class="img mob"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/00.jpg'"
                        />
                        <div class="work-hover-section">
                          <div class="hover-tag desc">{{ item.tag }}</div>
                          <h3 class="hover-title desc">{{ item.title }}</h3>
                          <p class="hover-desc desc">{{ item.desc }}</p>
                          <div class="hover-link desc mob">View case</div>
                        </div>
                      </div>
                    </transition>
                  </li>
                </ul>
                <ul
                  :class="[
                    'work-list work-list-featured',
                    { active: activeTag == 'Featured' },
                  ]"
                >
                  <li
                    :class="[
                      'work-list__item',
                      { visible: i < 4 },
                      item.folderName,
                    ]"
                    v-for="(item, i) in featuredWorks"
                    :key="i"
                    @click="openWorkModal(item)"
                  >
                    <transition
                      :name="
                        mobileScreen
                          ? item.animationFeatured
                          : item.animationFeaturedDesk
                      "
                    >
                      <div
                        :class="['img-container']"
                        v-show="activeTag == 'Featured'"
                        :style="{ backgroundColor: '#' + item.color }"
                      >
                        <img
                          class="img"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/0.png'"
                        />
                        <img
                          class="img mob"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/00.jpg'"
                        />
                        <div class="work-hover-section">
                          <div class="hover-tag desc">{{ item.tag }}</div>
                          <h3 class="hover-title desc">{{ item.title }}</h3>
                          <p class="hover-desc desc">{{ item.desc }}</p>
                          <div class="hover-link desc mob">View case</div>
                        </div>
                      </div>
                    </transition>
                  </li>
                </ul>
                <ul
                  :class="[
                    'work-list work-list-featured',
                    { active: activeTag == 'Featured' },
                  ]"
                >
                  <li
                    :class="[
                      'work-list__item',
                      { visible: i > 3 && moreWorks },
                      item.folderName,
                    ]"
                    v-for="(item, i) in featuredWorks"
                    :key="i"
                    @click="openWorkModal(item)"
                  >
                    <transition
                      :name="
                        mobileScreen
                          ? item.animationFeatured
                          : item.animationFeaturedDesk
                      "
                    >
                      <div
                        :class="['img-container']"
                        v-show="activeTag == 'Featured'"
                        :style="{ backgroundColor: '#' + item.color }"
                      >
                        <img
                          class="img"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/0.png'"
                        />
                        <img
                          class="img mob"
                          :alt="item.folderName"
                          v-lazy="'/uvio/works/' + item.folderName + '/00.jpg'"
                        />
                        <div class="work-hover-section">
                          <div class="hover-tag desc">{{ item.tag }}</div>
                          <h3 class="hover-title desc">{{ item.title }}</h3>
                          <p class="hover-desc desc">{{ item.desc }}</p>
                          <div class="hover-link desc mob">View case</div>
                        </div>
                      </div>
                    </transition>
                  </li>
                </ul>
                <button
                  class="link"
                  @click="loadMoreAction"
                  v-if="!moreWorks && activeTag"
                >
                  LOAD MORE
                </button>
              </div>
            </section>
            <section id="reviews" class="reviews" data-aos="fade-us">
              <div class="title">Reviews</div>
              <div class="reviews-wrapper">
                <div id="lSPrev" class="lSPrev"></div>
                <div id="lSNext" class="lSNext"></div>
                <div
                  id="slider"
                  class="slider list-unstyled cS-hidden reviews-carousel"
                >
                  <div
                    class="reviews__item"
                    v-for="(item, i) in reviews"
                    :key="i"
                  >
                    <div class="reviews__item-wrapper">
                      <h2 class="title">{{ item.title }}</h2>
                      <p class="desc">{{ item.desc }}</p>
                      <div class="desc pos">{{ item.pos }}</div>
                      <h4 class="desc subject">{{ item.subject }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="new-logos" data-aos="fade-us2">
              <a
                target="__blank"
                href="https://clutch.co/profile/uvio"
                class="item"
              >
                <img alt="Clutch" class="img" src="./../assets/clutch.svg" />
              </a>
              <a
                target="__blank"
                href="https://www.uvio.co/blog/manifest-award-2022"
                class="item"
              >
                <img
                  class="img"
                  alt="Manifest"
                  src="./../assets/minifest-air.png"
                />
              </a>
              <a
                href="https://www.upwork.com/o/companies/~0178ce9e74ff90c5c7"
                target="__blank"
                class="item"
              >
                <img
                  alt="UVIO upwork"
                  class="img"
                  src="./../assets/upwork.png"
                />
              </a>
            </div>
            <div class="blog-section" ref="blog" id="blog" data-aos="fade-us2">
              <div class="title">Blog</div>
              <div class="blogs-slick blog-list-low">
                <slick ref="slick" :options="slickOptionsBlog" class="carousel">
                  <div
                    class="item"
                    v-for="(item, i) in blog"
                    :key="i"
                    @click="openWorkModal(item, 'blog')"
                  >
                    <div class="wrapper">
                      <div class="img">
                        <img
                          :alt="item.title"
                          :src="require(`./../assets/${item.preview}.jpg`)"
                        />
                      </div>
                      <div class="desc date">{{ item.date }}</div>
                      <div class="title">{{ item.title }}</div>
                      <div class="desc">{{ item.desc }}</div>
                      <div class="button hover-link">Read article</div>
                    </div>
                  </div>
                </slick>
              </div>
            </div>
            <div class="footer" id="contactForm" data-aos="fade-us">
              <contact-form></contact-form>
              <div class="footer-bottom">
                <div class="left">
                  <div class="title-small">hello@uvio.co</div>
                  <div class="desc">
                    <span class="gray">UAE Office:</span>
                    <span>146 DTEC, Dubai Silicon Oasis, Dubai , UAE</span>
                  </div>
                  <div class="desc">
                    <span class="gray">US Office:</span>
                    <span
                      >16192 Coastal Highway, Lewes, Delaware 19958 USA</span
                    >
                  </div>
                  <div class="contact-btn-wrap">
                    <a
                      href="https://wa.me/971585154802"
                      target="_blank"
                      class="btn btn-whatsapp"
                    >
                      <span class="btn-icon">
                        <img
                          class="whatsapp-icon"
                          alt="Whatsapp"
                          v-lazy="require(`./../assets/socials/whatsapp.svg`)"
                        />
                      </span>
                      <span class="btn-text"
                        >Start chat in <b>Whatsapp</b></span
                      >
                    </a>
                    <a
                      href="https://telegram.me/kalaushin"
                      target="_blank"
                      class="btn btn-telegram"
                    >
                      <span class="btn-icon">
                        <img
                          class="telegram-icon"
                          alt="Telegram"
                          v-lazy="require(`./../assets/socials/telegram.svg`)"
                        />
                      </span>
                      <span class="btn-text"
                        >Start chat in <b>Telegram</b></span
                      >
                    </a>
                  </div>
                </div>
                <div class="right">
                  <ul class="list">
                    <li class="item" v-for="(item, i) in socials" :key="i">
                      <a :href="item.href" target="__blank" class="link">
                        <img
                          class="img"
                          alt="UVIO social"
                          v-lazy="
                            require(`./../assets/socials/${item.img}.svg`)
                          "
                        />
                      </a>
                    </li>
                  </ul>
                  <div class="desc">UVIO LLC © 2023</div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="shareModalVisible"
        class="overlay"
        @click="shareModalVisible = false"
      ></div>
    </transition>
    <transition name="fade">
      <ShareModal
        @closeShareModal="shareModalVisible = false"
        v-if="shareModalVisible"
        :title="activeBlog.title"
        :description="activeBlog.desc"
      />
    </transition>
    <el-dialog
      :visible.sync="workModalVisible"
      custom-class="work-modal"
      :before-close="closeWorkModal"
      :lock-scroll="true"
    >
      <transition name="fade">
        <div class="roller-container" v-if="loadedImages3 == 1">
          <div class="uvio-loader">
            <img
              class="img logo-sign"
              alt="UVIO design"
              v-lazy="'/uvio/logo-sign.png'"
            />
            <img
              class="img logo-red"
              alt="UVIO agency"
              v-lazy="'/uvio/logo-red.png'"
            />
            <img
              class="img logo-blue"
              alt="UVIO web design"
              v-lazy="'/uvio/logo-blue.png'"
            />
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div
          :class="['work-close', { active: modalContentVisible }]"
          @click="closeWorkModal"
        ></div>
      </transition>

      <transition name="slideUp">
        <div class="modal-wrapper blog-wrapper" v-if="activeBlogState">
          <div :class="['modal-content', { active: modalContentVisible }]">
            <Post @openShareModal="openShareModal" :activeBlog="activeBlog" />
          </div>
        </div>
      </transition>
      <transition name="slideUp">
        <div
          class="modal-wrapper"
          v-if="activeStory"
          :style="{ backgroundColor: '#' + activeWork.color }"
        >
          <div class="modal-wrap">
            <div
              :class="[
                'modal-content',
                { active: modalContentVisible && loadedImages3 == 2 },
                activeWork.tag,
              ]"
            >
              <div class="modal-top">
                <div class="modal-top-wrapper">
                  <div class="left">
                    <h2 class="title" v-if="activeWork.title">
                      {{ activeWork.title }}
                    </h2>
                    <div class="tag" v-if="activeWork.tag">
                      <span>{{ activeWork.tag }}</span>
                    </div>
                  </div>
                  <div class="right">
                    <h3 class="title small" v-if="activeWork.desc">
                      {{ activeWork.desc }}
                    </h3>
                    <h4 class="title-tiny" v-if="activeWork.challenge">
                      Challenge
                    </h4>
                    <p class="desc" v-if="activeWork.challenge">
                      {{ activeWork.challenge }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="middle-img" v-if="activeWork.folderName">
                <img
                  class="img"
                  :alt="activeWork.folderName"
                  v-lazy="'/uvio/works/' + activeWork.folderName + '/1.jpg'"
                />
              </div>
              <div class="brand-img" v-if="activeWork.brandBigImage">
                <img
                  class="img"
                  :alt="activeWork.folderName"
                  v-lazy="'/uvio/works/' + activeWork.folderName + '/brand.jpg'"
                />
              </div>

              <div class="modal-center" v-if="activeWork.outcome">
                <div class="modal-center-wrapper">
                  <div class="left">
                    <div class="title-tiny">Outcome</div>
                    <p class="desc">{{ activeWork.outcome }}</p>
                  </div>
                  <div class="right">
                    <ul class="list" v-if="activeWork.addition">
                      <li
                        class="item"
                        v-for="(item, i) in activeWork.addition"
                        :key="i"
                      >
                        <h2 class="title">{{ item.title }}</h2>
                        <div class="num">{{ item.number }}</div>
                        <p class="desc">{{ item.desc }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                class="img-list"
                v-if="activeWork.imagesCount && activeWork.tag == 'Mobile Apps'"
              >
                <img
                  v-for="(item, i) in activeWork.imagesCount"
                  :alt="activeWork.folderName"
                  :key="i"
                  class="img"
                  v-lazy="
                    '/uvio/works/' +
                    activeWork.folderName +
                    '/0' +
                    item +
                    '.png'
                  "
                />
              </div>
              <div
                class="img-list"
                v-if="activeWork.imagesCount && activeWork.tag != 'Mobile Apps'"
              >
                <img
                  v-for="(item, i) in activeWork.imagesCount"
                  :alt="activeWork.folderName"
                  :key="i"
                  class="img"
                  v-lazy="
                    '/uvio/works/' +
                    activeWork.folderName +
                    '/0' +
                    item +
                    '.jpg'
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </el-dialog>
    <notifications group="foo" />
    <SideTab />
  </div>
</template>

<script>
import Aos from 'aos';
import 'aos/dist/aos.css';

import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';

import { gsap } from 'gsap';
// TweenLite, TweenMax, Expo

import { ScrollSmoother } from 'gsap/ScrollSmoother.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText);

import works from './../works.json';
import worksCopy from './../worksCopy.json';

import blog from './../blog.json';

import Post from './../views/Post.vue';

import $ from 'jquery';

import ShareModal from './modals/shareModal.vue';
import ContactForm from '../components/ContactForm.vue';
import CustomHeader from '../components/CustomHeader.vue';
import SideTab from '../components/SideTab.vue';

export default {
  name: 'HomePage',
  components: {
    Slick,
    Post,
    ShareModal,
    ContactForm,
    CustomHeader,
    SideTab,
  },
  metaInfo: {
    title:
      'UVIO, Carefully devised digital products that strengthen your brand',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users',
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content:
          'UVIO, Carefully devised digital products that strengthen your brand',
      },
      {
        vmid: 'og:site_name',
        property: 'og:site_name',
        content:
          'UVIO, Carefully devised digital products that strengthen your brand',
      },
      {
        vmid: 'og:type',
        property: 'og:type',
        content: 'website',
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content:
          'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users',
      },
      {
        vmid: 'og:image',
        property: 'og:image',
        content: 'https://www.uvio.co/uvio/share-preview.jpg',
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: 'https://uvio.co',
      },
      {
        vmid: 'robots',
        name: 'robots',
        content: 'index,follow',
      },
      {
        vmid: 'twitter:card',
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        vmid: 'twitter:title',
        name: 'twitter:title',
        content:
          'UVIO, Carefully devised digital products that strengthen your brand',
      },
      {
        vmid: 'twitter:description',
        name: 'twitter:description',
        content:
          'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users',
      },
      {
        vmid: 'twitter:image',
        name: 'twitter:image',
        content: 'https://www.uvio.co/uvio/share-preview.jpg',
      },
      {
        vmid: 'twitter:image:alt',
        name: 'twitter:image:alt',
        content:
          'UVIO, Carefully devised digital products that strengthen your brand',
      },
    ],
  },
  data: function () {
    return {
      mobileScreen: false,
      scrollLoader: false,
      scrollLoaderLogo: false,
      scrollLoaderWork: false,
      visibleTitle: false,
      visibleHeroTitle: false,
      pageLoaded: false,
      imgContainerPos: 0,
      visibleButton: false,
      visibleButton2: false,
      visibleButton3: false,
      visibleButton4: false,
      loadedImages: 0,
      loadedImages2: 0,
      scrollPosition: 0,
      scrollDirectionUp: true,
      darkBgVisible: false,
      moreWorks: true,
      expertise: [
        {
          title: 'Business Development',
          desc: "At UVIO, we're passionate about enhancing operational efficiency. We excel in the art of streamlining operations by automating up to 70% of those laborious manual tasks that often bog businesses down. This transformation doesn't just save time; it's a catalyst for unlocking new opportunities. \n\nBy harnessing the power of top-tier third-party automation services, we enable businesses to operate more efficiently. This approach leaves their dedicated teams free to focus on high-impact, value-driven activities. Our commitment is not about reinventing the wheel; it's about putting the best tools to work for you, so you can achieve more with less effort.",
        },
        {
          title: 'Marketing',
          desc: "We're dedicated to driving remarkable revenue and profit growth for businesses. Leveraging advanced analytics, sales channel expansion, and innovative traffic sources, we bring about substantial enhancements in your company's financial performance in record time. \n\nOur expertise extends beyond the conventional. We provide invaluable counsel on crafting robust business models, rigorous hypothesis testing, and meticulous strategic planning. This elevates companies to new heights and paves the way for sustainable scaling, ensuring you're positioned for success in a dynamic market landscape.",
        },
        {
          title: 'Logo & Branding',
          desc: "We go beyond the surface to create visual identities that resonate with your audience on a profound level. Our logo design and branding services are not just about aesthetics; they are about crafting compelling narratives and making your business stand out.\n\nThrough a meticulous fusion of creativity, market research, and stakeholder insights, we bring your brand to life. Our team excels in designing logos that capture the essence of your business, leaving a lasting impression. We understand that branding is more than just a logo; it's the soul of your business.\n\nWith our expert guidance, we'll work closely with you to define your unique brand voice, ethos, and values.",
        },
        {
          title: 'Design & Development',
          desc: 'UVIO is your one-stop shop for product design, as well as web and app cross-platform development.\n\nFrom front- to back-end coding, we cover all of the bases.\n\nThe end result is a simultaneously minimalist and exhaustive digital experience you and your end-users never knew you needed.',
        },
        {
          title: 'User testing',
          desc: 'We have a proven track record of identifying organizational goals and strategizing around identified and targeted end-users. \n\nOur tried-and-true development method seamlessly flows from a discovery phase all the way through to realization with research and testing all along the way.',
        },
        {
          title: 'Post Launch & Support',
          desc: "Sure, we provide clients with all the source file in a perfect state, documentation they need and user-guides for the products we've build together. But we don't leave after projects are done. We're always there to help answer questions and, most importantly, guide our clients through their results to keep improving them. ",
        },
      ],
      tags: [
        'Featured',
        'Web Design',
        'Mobile Apps',
        'Web Platforms',
        'Branding',
      ],
      activeTag: 'Featured',
      activeCategory: 'Featured',
      activeExpertise: {},
      activeExpertiseChosen: '',
      expertiseAnimationStart: true,
      expertiseAnimationMiddle: true,
      expertiseAnimationEnd: true,
      slickOptions: {
        slidesToShow: 1,
        arrows: true,
        autoplay: false,
        infinite: false,
        centerPadding: '40px',
        dots: true,
        useTransform: false,
      },
      slickOptionsBlog: {
        slidesToShow: 3,
        arrows: true,
        autoplay: false,
        infinite: false,
        cssEase: 'cubic-bezier(0.3, 0.2, 0, 1)',
        centerPadding: '40px',
        dots: false,
        speed: 700,
        useTransform: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              variableWidth: true,
            },
          },
        ],
      },
      reviews: [
        {
          title:
            'The quality of his final product is better than most examples',
          desc: "I am so grateful to have worked with Uvio on this project. It's my first time using Upwork, so it was important for me to find the right partner. George was not only a great designer, but a great partner as well. From the first call he listened, provided great feedback, and asked good probing questions to offer valuable suggestions. Once we got started on the design, Designer was organized, stuck to the schedule, and always available/easy to get a hold of. ",
          pos: 'Sassan Mostofi, CEO',
          subject: 'Mobile App UI Prototype',
        },
        {
          title: 'Fantastic result!',
          desc: 'As Popsters Team we engaged UVIO to work with many big and interesting tasks. UVIO team developed a UX/UI for Single Page Applications (SPA) Popsters, and a big one - more complicated app with some much charts and dashboards. Design Lead Yury can make a great small project and a large and complex. He helped us to make a design for global researches (presentations), landing pages, logo design, product UI and animations. If you are looking for a professional that can immerse to your business and make a cool result - UVIO will help you.',
          pos: 'Arseniy Kushnir, CEO',
          subject: 'Complex analytics platform UX/UI',
        },
        {
          title: 'These guys are killing it',
          desc: 'We came with just a high-level idea and unfleshed features. Designer asked the right questions and helped us frame what we wanted. He was also responsive and highly efficient in making the changes we asked for. \nI would hire George again for any mobile/web design work that I may need.',
          pos: 'Marcellus Davenport, CEO',
          subject: 'UI/UX designer for digital coupon mobile app',
        },
        {
          title: 'The best UI/UX designer we have worked with',
          desc: 'By far the best UI/UX designers we have worked with. They repeatedly goes above and beyond what is asked for and always produces top quality work. They also helps challenge the thinking by being a thought partner in every effort and helps you get to the best possible outcome. More than that, they are kind, easy to communicate with, and extremely responsive. Would highly recommend working with Uvio! Clearly one of the best Upwork has to offer.',
          pos: 'Anthony Marshi, CEO',
          subject: 'UI Design for a mobile app',
        },
        {
          title: 'Knowledgeable in the art of user experience!',
          desc: 'Not only they are skilled in design, but they are also knowledgeable in the art of user experience. Uvio team was able to communicate the tradeoffs of different designs and quickly implement them. I would definitely hire Uvio for future projects.',
          pos: 'Marcellus Davenport, CEO',
          subject: 'Website Revamp',
        },

        {
          title: 'Very pleased with the work!',
          desc: 'We are very pleased with the work. Designer communicated very well and understood our requirements! \nHe provided the design and resources in timely manner. For sure we will work with Uvio again for future projects!',
          pos: 'Steven Skunberg',
          subject: 'Cymatics.fm',
        },
      ],
      socials: [
        {
          img: 'linkedIn',
          href: 'https://www.linkedin.com/company/uvio-design',
        },
        // {
        //   'img': 'instagram',
        //   'href': '#'
        // },
        {
          img: 'dribbble',
          href: 'https://dribbble.com/uvio',
        },
        {
          img: 'facebook',
          href: 'https://www.facebook.com/uvioagency',
        },
        {
          img: 'behance',
          href: 'https://www.behance.net/uvioagency',
        },
      ],
      works: works,
      worksCopy: worksCopy,
      workModalVisible: false,
      modalContentVisible: false,
      activeWork: {},
      activeStory: false,
      nextHidden: false,
      prevHidden: false,
      logosReverse: 1,
      logosReverse2: 1,
      logosReverse3: 1,
      logosReverse4: 1,
      featuredWorks: [],
      webDesignWorks: [],
      mobileAppsWorks: [],
      webPlatformsWorks: [],
      brandingWorks: [],
      formSucceed: false,
      loadedImages3: 0,
      isSafariOld: false,
      isSafari: false,
      blog: blog,
      activeBlog: {},
      activeBlogState: false,
      shareModalVisible: false,
      menuIsOpen: false,
      menuLinkIsVisible: false,
      menuLinkIsVisible1: false,
      menuLinkIsVisible2: false,
      menuLinkIsVisible3: false,
      menuLinkIsVisible4: false,
      menuLinkIsVisible5: false,
      menuLinkIsVisible6: false,
    };
  },
  created() {
    let img = ['bgBlur.jpg', 'bgBlurMob.png'];
    let cln = ['bgBlur.jpg', 'bgBlurMob.png'];

    for (var i = 0; i <= 1; i++) {
      img[i] = new Image();
      img[i].src = '/uvio/' + cln[i];

      img[i].onload = () => {
        this.loadedImages++;
      };
    }

    Aos.init({
      once: true,
    });
  },
  watch: {
    loadedImages(newQuestion) {
      let self = this;
      if (newQuestion == 2) {
        this.$nextTick(() => {
          let functions = [
            self.sponsorsChange,
            self.sponsorsChange2,
            self.sponsorsChange3,
            self.sponsorsChange4,
          ];
          self.activeExpertise = this.expertise[0];
          self.activeExpertiseChosen = this.expertise[0].title;
          setTimeout(function () {
            let prevFunc = 0;
            setInterval(function () {
              if (document.hasFocus()) {
                let i = self.randomNumber(functions.length);
                if (prevFunc === i && i === 3) {
                  prevFunc = prevFunc - 1;
                  functions[prevFunc]();
                } else if (prevFunc === i) {
                  prevFunc = prevFunc + 1;
                  functions[prevFunc]();
                } else {
                  functions[i]();
                  prevFunc = i;
                }
              }
            }, 6000);

            let cln2 = self.worksCopy;
            let img2 = cln2;
            let arr = [];
            for (let l = 0; l < cln2.length; l++) {
              arr.push(cln2[l].folderName);

              img2[l] = new Image();
              img2[l].src = '/uvio/works/' + arr[l] + '/0.png';

              img2[l].onload = () => {
                self.loadedImages2++;
              };
            }
            let img;
            img = new Image();
            img.src = '/uvio/close.svg';
          }, 4000);
          setTimeout(function () {
            self.pageLoaded = true;
            $(window).scrollTop(0);
            self.smotherToggle();

            if (window.matchMedia('(min-width: 769px)').matches) {
              setTimeout(function () {
                self.visibleTitle = true;
              }, 1200);
              setTimeout(function () {
                self.visibleHeroTitle = true;
              }, 1500);
            }
            self.wordingAnimation();
          }, 400);

          setTimeout(function () {
            if (window.matchMedia('(min-width: 769px)').matches) {
              self.setHeaderSwitch();
              ScrollTrigger.create({
                trigger: '.fixedImg',
                pin: true,
                start: 'center center',
                endTrigger: '.text-wrapper-last',
                end: 'bottom +=50px',
                pinSpacing: false,
              });
              ScrollTrigger.create({
                trigger: '.header-logo',
                pin: true,
                start: 0,
                endTrigger: '.footer',
                end: 'bottom +=0px',
                pinSpacing: false,
              });
              ScrollTrigger.create({
                trigger: '.right-fixed',
                pin: true,
                start: 0,
                endTrigger: '.footer',
                end: 'bottom +=0px',
                pinSpacing: false,
              });

              gsap.to('.fixedImg .img', {
                scrollTrigger: {
                  trigger: '.fixedImg .img',
                  start: 'center center',
                  endTrigger: '.text-wrapper-last',
                  end: 'bottom +=50px',
                  pinSpacing: false,
                  scrub: true,
                },
                x: '-600',
                y: 130,
                ease: 'none',
              });

              if (window.matchMedia('(min-width: 1922px)').matches) {
                gsap.to('.text-wrapper-1 .desc', {
                  scrollTrigger: {
                    trigger: '.text-wrapper-1 .desc',
                    start: 'top bottom-=20px',
                    end: 'bottom 1200px',
                    pinSpacing: false,
                    scrub: true,
                  },
                  x: '0',
                  y: '-130',
                  ease: 'none',
                });

                gsap.to('.text-wrapper-2 .desc', {
                  scrollTrigger: {
                    trigger: '.text-wrapper-2 .desc',
                    start: 'top bottom-=20px',
                    end: 'bottom 1200px',
                    pinSpacing: false,
                    scrub: true,
                  },
                  x: '0',
                  y: '-130',
                  ease: 'none',
                });

                gsap.to('.text-wrapper-3 .desc', {
                  scrollTrigger: {
                    trigger: '.text-wrapper-3 .desc',
                    start: 'top bottom-=20px',
                    end: 'bottom 1200px',
                    pinSpacing: false,
                    scrub: true,
                  },
                  x: '0',
                  y: '-130',
                  ease: 'none',
                });

                gsap.to('.text-wrapper-4 .desc', {
                  scrollTrigger: {
                    trigger: '.text-wrapper-4 .desc',
                    start: 'top bottom-=20px',
                    end: 'bottom 1200px',
                    pinSpacing: false,
                    scrub: true,
                  },
                  x: '0',
                  y: '-130',
                  ease: 'none',
                });
                gsap.from('.header', {
                  scrollTrigger: {
                    start: 'top bottom',
                    end: 'bottom top',
                    trigger: '.header',
                    toggleClass: 'inactive',
                  },
                });
                gsap.from('.header-logo', {
                  scrollTrigger: {
                    start: 'top bottom',
                    end: 'bottom top',
                    trigger: '.header',
                    toggleClass: 'white',
                  },
                });
                if (document.getElementById('darkSection')) {
                  let startPos =
                    document
                      .getElementById('darkSection')
                      .getBoundingClientRect().top - 50;
                  let endPos =
                    document
                      .getElementById('darkSection')
                      .getBoundingClientRect().top + 820;
                  gsap.from('.header-logo', {
                    scrollTrigger: {
                      start: startPos,
                      end: endPos,
                      trigger: '.header-logo',
                      toggleClass: 'white',
                    },
                  });
                }
                if (document.getElementById('contactForm')) {
                  let startPos1 =
                    document
                      .getElementById('contactForm')
                      .getBoundingClientRect().top - 60;
                  let endPos1 =
                    document
                      .getElementById('contactForm')
                      .getBoundingClientRect().top + 500;
                  gsap.from('.header-logo', {
                    scrollTrigger: {
                      start: startPos1,
                      end: endPos1,
                      trigger: '.header-logo',
                      toggleClass: 'white',
                    },
                  });
                }
              }
            }
            self.scrollTriggerPack();
          }, 500);
        });
      }
    },
  },
  mounted: function () {
    if (window.matchMedia('(max-width: 768px)').matches) {
      this.mobileScreen = true;
    }

    let self = this;
    let url = window.location.href;
    works.length;
    for (var i = 0; i < works.length; i++) {
      let urlPart = works[i].tag + '/' + works[i].title;
      urlPart = urlPart.replace(/ /g, '_').toLowerCase();

      if (url.includes(urlPart)) {
        self.openWorkModal(works[i]);
      }
    }

    blog.length;
    for (var a = 0; a < blog.length; a++) {
      let urlPart2 = 'blog/' + blog[a].url;

      if (url.includes(urlPart2)) {
        self.openWorkModal(blog[a], 'blog');
      }
    }

    if (window.matchMedia('(max-width: 768px)').matches) {
      self.moreWorks = true;
    }

    let browser = this.get_browser();
    // console.log('browser.name', browser.name);
    // console.log('browser.name', parseInt(browser.version));
    if (browser.name == 'Safari') {
      this.isSafari = true;
    }
    if (browser.name == 'Safari' && parseInt(browser.version) < 13) {
      this.isSafariOld = true;
    }

    $(document).ready(function () {
      // $(window).scrollTop(0);
    });

    this.$nextTick(() => {
      self.smotherToggle();
      window.addEventListener('resize', function () {
        self.smotherToggle();
        self.wordingAnimation();
      });
      document.addEventListener('mousemove', this.onMouseMove, {
        passive: true,
      });

      window.addEventListener('scroll', this.updateScroll, { passive: true });

      let newWorks = self.works.filter((el) => el.featured == true);
      let sortedWorks = newWorks
        .sort((a, b) => a.featuredOrder - b.featuredOrder)
        .sort();
      this.featuredWorks = sortedWorks;

      this.webDesignWorks = self.works.filter((el) => el.tag == 'Web Design');
      this.mobileAppsWorks = self.works.filter((el) => el.tag == 'Mobile Apps');
      this.webPlatformsWorks = self.works.filter(
        (el) => el.tag == 'Web Platforms'
      );
      this.brandingWorks = self.works.filter((el) => el.tag == 'Branding');

      setTimeout(() => {
        let slider = $('#slider').lightSlider({
          gallery: true,
          item: 1,
          slideMargin: 0,
          auto: false,
          useCSS: false,
          loop: false,
          freeMove: false,
          controls: false,
          enableTouch: false,
          enableDrag: false,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                useCSS: true,
                loop: true,
                freeMove: false,
                controls: true,
                enableTouch: true,
                slideEndAnimation: false,
                enableDrag: false,
              },
            },
          ],
          onSliderLoad: function () {
            $('#slider').removeClass('cS-hidden');
          },
        });

        $('.lSNext').click(function () {
          // if (!slider.find('.lslide').last().hasClass('active')) {
          slider.goToNextSlide();
          // } else {
          //   slider.find('.lslide').last().addClass('rightEnd');
          //   setTimeout(function() {
          //     slider.find('.lslide').last().removeClass('rightEnd');
          //   }, 200)
          // }
        });

        $('.lSPrev').click(function () {
          if (!slider.find('.lslide').first().hasClass('active')) {
            slider.goToPrevSlide();
          } else {
            slider.find('.lslide').first().addClass('leftEnd');
            setTimeout(function () {
              slider.find('.lslide').first().removeClass('leftEnd');
            }, 200);
          }
        });

        setTimeout(function () {
          window.dispatchEvent(new Event('resize'));
        }, 500);
        setTimeout(function () {
          window.dispatchEvent(new Event('resize'));
        }, 1000);
      }, 4000);
    });
  },
  methods: {
    loadMoreAction() {
      let self = this;
      self.moreWorks = true;
      setTimeout(function () {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    },
    smotherToggle() {
      if (window.matchMedia('(min-width: 769px)').matches) {
        ScrollSmoother.create({
          wrapper: '#app',
          content: '#smoothContent',
          smoooth: 2,
          smoothTouch: 0.1,
        });
      }
    },
    get_browser() {
      var ua = navigator.userAgent,
        tem,
        M =
          ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
          ) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: 'IE', version: tem[1] || '' };
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR|Edge\/(\d+)/);
        if (tem != null) {
          return { name: 'Opera', version: tem[1] };
        }
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
      }
      return {
        name: M[0],
        version: M[1],
      };
    },
    randomNumber(n) {
      return Math.floor(Math.random() * n);
    },
    sponsorsChange() {
      let self = this;
      if (self.logosReverse == 1) {
        self.logosReverse = 0;
        setTimeout(function () {
          self.logosReverse = 2;
        }, 2000);
      }
      if (self.logosReverse == 2) {
        self.logosReverse = 0;
        setTimeout(function () {
          self.logosReverse = 1;
        }, 2000);
      }
    },
    sponsorsChange2() {
      let self = this;
      if (self.logosReverse2 == 1) {
        self.logosReverse2 = 0;
        setTimeout(function () {
          self.logosReverse2 = 2;
        }, 2000);
      }
      if (self.logosReverse2 == 2) {
        self.logosReverse2 = 0;
        setTimeout(function () {
          self.logosReverse2 = 1;
        }, 2000);
      }
    },
    sponsorsChange3() {
      let self = this;
      if (self.logosReverse3 == 1) {
        self.logosReverse3 = 0;
        setTimeout(function () {
          self.logosReverse3 = 2;
        }, 2000);
      }
      if (self.logosReverse3 == 2) {
        self.logosReverse3 = 0;
        setTimeout(function () {
          self.logosReverse3 = 1;
        }, 2000);
      }
    },
    sponsorsChange4() {
      let self = this;
      if (self.logosReverse4 == 1) {
        self.logosReverse4 = 0;
        setTimeout(function () {
          self.logosReverse4 = 2;
        }, 2000);
      }
      if (self.logosReverse4 == 2) {
        self.logosReverse4 = 0;
        setTimeout(function () {
          self.logosReverse4 = 3;
        }, 2000);
      }
      if (self.logosReverse4 == 3) {
        self.logosReverse4 = 0;
        setTimeout(function () {
          self.logosReverse4 = 1;
        }, 2000);
      }
    },
    onMouseMove(e) {
      // let form = document.getElementById('form');
      let btn = document.getElementById('our-works-main-btn');
      // let btn2 = document.getElementById('btn2');
      let circle = document.getElementById('circle');
      let circle2 = document.getElementById('circle2');

      if (window.matchMedia('(min-width: 768px)').matches) {
        if (circle) {
          circle.style.transform =
            'translate(' + e.pageX + 'px,' + e.pageY + 'px)';

          if (window.matchMedia('(min-width: 1921px)').matches) {
            circle.style.margin = +(-btn.offsetTop - 900) + 'px 0px 0px -48vw';
          } else if (window.matchMedia('(min-width: 1440px)').matches) {
            circle.style.margin = +(-btn.offsetTop - 700) + 'px 0px 0px -55vw';
          } else {
            circle.style.margin = +(-btn.offsetTop - 500) + 'px 0px 0px -48vw';
          }
        }
        if (circle2) {
          circle2.style.left = e.pageX + 'px';
          circle2.style.top = e.pageY + 'px';
          if (window.matchMedia('(max-width: 1921px)').matches) {
            circle2.style.margin =
              -$(document).height() + 250 + 'px 0px 0px -43vw';
          } else {
            circle2.style.margin =
              -$(document).height() + 400 + 'px 0px 0px -46vw';
          }
        }
      }
    },
    titleAnim() {
      if (window.matchMedia('(min-width: 769px)').matches) {
        // var split = new SplitText('.hero .title');
        // TweenLite.set('.hero .title', {perspective: 400});
        // TweenMax.staggerFrom(split.chars, 0.8, {opacity: 0, scale: 1, y: 0, rotationX: 0, transform: "translateX(80px)", ease: Expo.easeOut}, 0.07, function() {
        //   split.revert()
        // })
      }
    },
    wordingAnimation() {
      let self = this;
      self.titleAnim();

      if (window.matchMedia('(max-width: 768px)').matches) {
        setTimeout(function () {
          self.visibleButton = true;
        }, 500);
        setTimeout(function () {
          self.visibleTitle = true;
        }, 1000);
        setTimeout(function () {
          self.visibleHeroTitle = true;
        }, 1000);
        setTimeout(function () {
          self.visibleButton2 = true;
        }, 1600);
        setTimeout(function () {
          self.visibleButton3 = true;
        }, 2000);
        setTimeout(function () {
          self.visibleButton4 = true;
        }, 2400);
      } else {
        setTimeout(function () {
          self.visibleButton = true;
        }, 500);
        setTimeout(function () {
          self.visibleTitle = true;
        }, 700);
        setTimeout(function () {
          self.visibleHeroTitle = true;
        }, 1000);
        setTimeout(function () {
          self.visibleButton2 = true;
        }, 1500);
        setTimeout(function () {
          self.visibleButton3 = true;
        }, 2000);
        setTimeout(function () {
          self.visibleButton4 = true;
        }, 2500);
      }
    },
    filterWorksByTag(l) {
      let self = this;
      self.activeTag = '';
      if (window.matchMedia('(max-width: 768px)').matches) {
        self.moreWorks = true;
      }
      self.activeCategory = l;
      setTimeout(() => {
        self.activeTag = l;
      }, 600);
    },
    setExpertise(l) {
      let self = this;
      self.activeExpertise = '';
      self.activeExpertiseChosen = l.title;
      self.expertiseAnimationStart = false;
      self.expertiseAnimationMiddle = false;
      self.expertiseAnimationEnd = false;
      setTimeout(function () {
        self.activeExpertise = l;
      }, 300);
      setTimeout(function () {
        self.expertiseAnimationStart = true;
      }, 500);
      setTimeout(function () {
        self.expertiseAnimationMiddle = true;
      }, 700);
      setTimeout(function () {
        self.expertiseAnimationEnd = true;
      }, 900);
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
      if (this.lastScroll <= this.scrollPosition) {
        this.lastScroll = this.scrollPosition;
        this.scrollDirectionUp = false;
      } else {
        this.lastScroll = this.scrollPosition;
        this.scrollDirectionUp = true;
      }
    },
    scrollTriggerPack() {
      if (
        window.matchMedia('(max-width: 1921px)').matches &&
        window.matchMedia('(min-width: 769px)').matches
      ) {
        gsap.from('.header', {
          scrollTrigger: {
            start: 'top bottom',
            end: 'bottom top',
            trigger: '.header',
            toggleClass: 'inactive',
          },
        });
        if (document.getElementById('darkSection')) {
          let startPos =
            document.getElementById('darkSection').getBoundingClientRect().top -
            100;
          let endPos =
            document.getElementById('darkSection').getBoundingClientRect().top +
            500;
          gsap.from('.header-logo', {
            scrollTrigger: {
              start: startPos,
              end: endPos,
              trigger: '.header-logo',
              toggleClass: 'white',
            },
          });
        }

        setTimeout(function () {
          if (document.getElementById('contactForm')) {
            let startPos1 =
              document.getElementById('contactForm').getBoundingClientRect()
                .top - 50;
            let endPos1 =
              document.getElementById('contactForm').getBoundingClientRect()
                .top + 500;

            gsap.from('.header-logo', {
              scrollTrigger: {
                start: startPos1,
                end: endPos1,
                trigger: '.header-logo',
                toggleClass: 'white',
              },
            });
          }
        }, 500);

        gsap.to('.text-wrapper-1 .desc', {
          scrollTrigger: {
            trigger: '.text-wrapper-1 .desc',
            start: 'top bottom-=20px',
            end: 'bottom 800px',
            pinSpacing: false,
            scrub: true,
          },
          x: '0',
          y: '-130',
          ease: 'none',
        });

        gsap.to('.text-wrapper-2 .desc', {
          scrollTrigger: {
            trigger: '.text-wrapper-2 .desc',
            start: 'top bottom-=20px',
            end: 'bottom 800px',
            pinSpacing: false,
            scrub: true,
          },
          x: '0',
          y: '-130',
          ease: 'none',
        });

        gsap.to('.text-wrapper-3 .desc', {
          scrollTrigger: {
            trigger: '.text-wrapper-3 .desc',
            start: 'top bottom-=20px',
            end: 'bottom 800px',
            pinSpacing: false,
            scrub: true,
          },
          x: '0',
          y: '-130',
          ease: 'none',
        });

        gsap.to('.text-wrapper-4 .desc', {
          scrollTrigger: {
            trigger: '.text-wrapper-4 .desc',
            start: 'top bottom-=20px',
            end: 'bottom 800px',
            pinSpacing: false,
            scrub: true,
          },
          x: '0',
          y: '-130',
          ease: 'none',
        });

        gsap.to('.text-wrapper-1 span', {
          scrollTrigger: {
            trigger: '.text-wrapper-1 span',
            start: 'top bottom-=20px',
            end: 'bottom 550px',
            pinSpacing: false,
            scrub: true,
          },
          x: '0',
          y: '-130',
          ease: 'none',
        });
        gsap.to('.text-wrapper-2 span', {
          scrollTrigger: {
            trigger: '.text-wrapper-2 span',
            start: 'top bottom-=20px',
            end: 'bottom 550px',
            pinSpacing: false,
            scrub: true,
          },
          x: '0',
          y: '-130',
          ease: 'none',
        });
        gsap.to('.text-wrapper-3 span', {
          scrollTrigger: {
            trigger: '.text-wrapper-3 span',
            start: 'top bottom-=20px',
            end: 'bottom 550px',
            pinSpacing: false,
            scrub: true,
          },
          x: '0',
          y: '-130',
          ease: 'none',
        });
        gsap.to('.text-wrapper-4 span', {
          scrollTrigger: {
            trigger: '.text-wrapper-4 span',
            start: 'top bottom-=20px',
            end: 'bottom 550px',
            pinSpacing: false,
            scrub: true,
          },
          x: '0',
          y: '-130',
          ease: 'none',
        });
      }
      if (window.matchMedia('(max-width: 768px)').matches) {
        if (document.getElementById('darkSection')) {
          let startPos =
            document.getElementById('darkSection').getBoundingClientRect().top -
            50;
          let endPos =
            document.getElementById('darkSection').getBoundingClientRect().top +
            document.getElementById('darkSection').offsetHeight -
            50;
          gsap.from('.header-logo', {
            scrollTrigger: {
              start: startPos,
              end: endPos,
              trigger: '.header-logo',
              toggleClass: 'white',
            },
          });
        }
        if (document.getElementById('contactFormWrapper')) {
          let startPos2 =
            document
              .getElementById('contactFormWrapper')
              .getBoundingClientRect().top + 500;
          let endPos2 =
            document.getElementById('contactForm').getBoundingClientRect().top +
            document.getElementById('contactForm').offsetHeight +
            500;
          gsap.from('.header-logo', {
            scrollTrigger: {
              start: startPos2,
              end: endPos2,
              trigger: '.header-logo',
              toggleClass: 'white',
            },
          });
        }
      }
    },
    closeWorkModal() {
      let self = this;
      self.modalContentVisible = false;
      history.pushState({}, null, '/');
      setTimeout(() => {
        self.activeStory = false;
        self.activeBlogState = false;
        self.loadedImages3 = 0;
      }, 600);
      setTimeout(() => {
        self.workModalVisible = false;
        self.activeBlog = '';
        self.activeWork = '';
      }, 1300);
    },
    openWorkModal(work, blog) {
      if (blog == 'blog') {
        let url = 'blog/' + work.url;
        if (!window.location.href.includes(url)) {
          history.pushState({}, null, url);
        }
        this.activeBlog = work;

        this.workModalVisible = true;
        setTimeout(() => {
          this.activeBlogState = true;
        }, 300);
        setTimeout(() => {
          this.modalContentVisible = true;
        }, 1000);
      } else {
        let url = work.tag + '/' + work.title;
        url = url.replace(/ /g, '_').toLowerCase();
        if (!window.location.href.includes(url)) {
          history.pushState({}, null, url);
        }

        let self = this;
        let img = work;
        let cln = work;
        img = new Image();
        img.src = '/uvio/works/' + cln.folderName + '/1.jpg';

        // setTimeout(function() {
        // if (self.loadedImages3 == 0) {
        self.loadedImages3 = 1;
        // }
        // }, 700)

        // img.onload = () => {
        // if (self.loadedImages3 == 0) {
        this.loadedImages3 = 2;
        // }
        // setTimeout(function() {
        // if (self.loadedImages3 == 1) {
        self.loadedImages3 = 2;
        // }
        // }, 700)
        // }
        this.activeWork = work;

        this.workModalVisible = true;
        setTimeout(() => {
          this.activeStory = true;
        }, 300);
        setTimeout(() => {
          this.modalContentVisible = true;
        }, 1000);
      }
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', function () {
        history.pushState(null, null, document.URL);
        self.closeWorkModal();
      });
    },
    book() {
      window.open(
        'https://calendly.com/uvio-design/30min',
        '_blank' // <- This is what makes it open in a new window.
      );
    },
    ourWorksScroll() {
      // this.toggleMenu();
      let self = this;
      setTimeout(() => {
        self.scrollLoaderWork = true;
        self.scrollLoaderLogo = true;
      }, 300);
      setTimeout(() => {
        self.scrollLoaderLogo = false;
      }, 2400);
      setTimeout(() => {
        self.scrollLoaderWork = false;
      }, 3500);
      this.$scrollTo('.works', {
        duration: 2500,
        easing: 'cubic-bezier(1, 0, 0.5, 1)',
        offset: -100,
      });
    },
    caseStudiesScroll() {
      this.toggleMenu();
      let self = this;
      setTimeout(() => {
        self.scrollLoaderWork = true;
        self.scrollLoaderLogo = true;
      }, 300);
      setTimeout(() => {
        self.scrollLoaderLogo = false;
      }, 2400);
      setTimeout(() => {
        self.scrollLoaderWork = false;
      }, 3500);
      this.$scrollTo('.works', {
        duration: 2500,
        easing: 'cubic-bezier(1, 0, 0.5, 1)',
        offset: -100,
      });
    },

    reviewsScroll() {
      this.toggleMenu();
      let self = this;
      setTimeout(() => {
        self.scrollLoader = true;
        self.scrollLoaderLogo = true;
      }, 200);
      setTimeout(() => {
        self.scrollLoaderLogo = false;
      }, 1600);
      setTimeout(() => {
        self.scrollLoader = false;
      }, 2500);
      this.$scrollTo('.reviews', {
        duration: 2200,
        easing: 'cubic-bezier(1, 0, 0.5, 1)',
        offset: 50,
      });
    },
    blogScroll() {
      this.toggleMenu();
      let self = this;
      setTimeout(() => {
        self.scrollLoader = true;
        self.scrollLoaderLogo = true;
      }, 200);
      setTimeout(() => {
        self.scrollLoaderLogo = false;
      }, 1900);
      setTimeout(() => {
        self.scrollLoader = false;
      }, 2500);
      this.$scrollTo('.blog-section', {
        duration: 2000,
        easing: 'cubic-bezier(1, 0, 0.5, 1)',
        offset: -50,
      });
    },

    expertiseScroll() {
      this.toggleMenu();
      let self = this;
      setTimeout(() => {
        self.scrollLoader = true;
        self.scrollLoaderLogo = true;
      }, 200);
      setTimeout(() => {
        self.scrollLoaderLogo = false;
      }, 1600);
      setTimeout(() => {
        self.scrollLoader = false;
      }, 2500);
      this.$scrollTo('.dark-section', {
        duration: 2300,
        easing: 'cubic-bezier(1, 0, 0.5, 1)',
        offset: -100,
      });
    },

    superpowersScroll() {
      this.toggleMenu();
      let self = this;
      setTimeout(() => {
        self.scrollLoader = true;
        self.scrollLoaderLogo = true;
      }, 200);
      setTimeout(() => {
        self.scrollLoaderLogo = false;
      }, 1600);
      setTimeout(() => {
        self.scrollLoader = false;
      }, 2500);
      this.$scrollTo('.superpower', {
        duration: 2000,
        easing: 'cubic-bezier(1, 0, 0.5, 1)',
        offset: 50,
      });
    },
    setHeaderSwitch() {
      let self = this;
      setTimeout(function () {
        self.$nextTick(function () {
          $('.dark-section-inside-item').each(function () {
            $(this).click(function () {
              $('.expertise-switch').css(
                'top',
                $(this).offset().top -
                  $('.dark-section-inside-item').first().offset().top +
                  40 +
                  'px'
              );
            });
          });
        });
      }, 1000);
    },
    openShareModal() {
      this.shareModalVisible = true;
    },
    toggleMenu() {
      this.menuIsOpen = !this.menuIsOpen;

      setTimeout(() => {
        this.menuLinkIsVisible = !this.menuLinkIsVisible;
      }, 100);
      setTimeout(() => {
        this.menuLinkIsVisible1 = !this.menuLinkIsVisible1;
      }, 200);
      setTimeout(() => {
        this.menuLinkIsVisible2 = !this.menuLinkIsVisible2;
      }, 300);
      setTimeout(() => {
        this.menuLinkIsVisible3 = !this.menuLinkIsVisible3;
      }, 400);
      setTimeout(() => {
        this.menuLinkIsVisible4 = !this.menuLinkIsVisible4;
      }, 500);
      setTimeout(() => {
        this.menuLinkIsVisible5 = !this.menuLinkIsVisible5;
      }, 600);
      setTimeout(() => {
        this.menuLinkIsVisible6 = !this.menuLinkIsVisible6;
      }, 700);
    },
    scrollToForm() {
      this.toggleMenu();
      this.$scrollTo('.footer', {
        duration: 2500,
        easing: 'cubic-bezier(.01,.23,0,.98)',
      });
    },
    scrollToAbout() {
      this.toggleMenu();
      this.$scrollTo('.section-2', {
        duration: 700,
        easing: 'cubic-bezier(.01,.23,0,.98)',
      });
    },
    scrollToTop() {
      this.toggleMenu();
      this.$scrollTo('.header', {
        duration: 2500,
        easing: 'cubic-bezier(.01,.23,0,.98)',
      });
    },
  },
};
</script>
