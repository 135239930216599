<template>
  <div id="app">
    <div class="page post-page page-not-found">
      <div class="page-wrapper">
        <header class="header">
          <div class="header-wrapper visible">
            <a class="header-logo" href="https://www.uvio.co/">
              <img
                class="img desk"
                alt="UVIO"
                src="./../assets/post/logo.svg"
              />
              <img
                class="img mob"
                alt="UVIO"
                src="./../assets/post/logoMob.svg"
              />
            </a>
            <div class="right right-fixed">
              <a
                href="https://calendly.com/uvio-design/30min"
                target="__blank"
                id="book-a-call-btn"
                alt="Book a call with UVIO"
                class="button-book book-a-call-btn"
              >
                <div class="button-book-wrapper">
                  <span>Book a Call</span>
                  <img
                    class="img"
                    alt="Calendar"
                    src="./../assets/post/calendar.svg"
                  />
                </div>
              </a>
            </div>
          </div>
        </header>
        <main class="main">
          <section class="dark-section">
            <div class="dark-section-wrapper">
              <img class="img blue" alt="UVIO team" v-lazy="'/uvio/blue.png'" />
              <img class="img red" alt="UVIO team" v-lazy="'/uvio/red.png'" />
              <div class="dark-section-inside">
                <div class="glitch-container">
                  <div class="container">
                    <div class="glitch" data-text="4  0  4">4 0 4</div>
                    <div class="glow">4 0 4</div>
                  </div>
                  <div class="scanlines"></div>
                </div>

                <div class="desc">You know what does it mean...</div>
                <a href="https://www.uvio.co/" class="button">HOME PAGE</a>
              </div>
            </div>
          </section>
        </main>
        <div class="footer">
          <div class="footer-bottom">
            <div class="left">
              <div class="title-small">hello@uvio.co</div>
              <div class="desc">
                    <span class="gray">UAE Office:</span>
                    <span
                      >146 DTEC, Dubai Silicon Oasis, Dubai , UAE</span
                    >
                  </div>
                  <div class="desc">
                    <span class="gray">US Office:</span>
                    <span
                      >16192 Coastal Highway, Lewes, Delaware 19958 USA</span
                    >
                  </div>
                  <div class="contact-btn-wrap">
                    <a href="https://wa.me/971585154802" target="_blank" class="btn btn-whatsapp">
                      <span class="btn-icon">
                        <img
                          class="whatsapp-icon"
                          alt="Whatsapp"
                          v-lazy="
                            require(`./../assets/socials/whatsapp.svg`)
                          "
                        />
                      </span>
                      <span class="btn-text">Start chat in <b>Whatsapp</b></span>
                    </a>
                    <a href="/" class="btn btn-telegram">
                      <span class="btn-icon">
                        <img
                          class="telegram-icon"
                          alt="Whatsapp"
                          v-lazy="
                            require(`./../assets/socials/telegram.svg`)
                          "
                        />
                      </span>
                      <span class="btn-text">Start chat in <b>Telegram</b></span>
                    </a>
                  </div>
            </div>
            <div class="right">
              <ul class="list">
                <li class="item">
                  <a
                    href="https://www.linkedin.com/company/uvio-design"
                    target="__blank"
                    class="link"
                    ><img
                      src="./../assets/post/linkedIn.svg"
                      alt="LinkedIn"
                      class="img"
                  /></a>
                </li>
                <li class="item">
                  <a
                    href="https://dribbble.com/uvio"
                    target="__blank"
                    class="link"
                    ><img
                      src="./../assets/post/dribbble.svg"
                      alt="Dribble"
                      class="img"
                  /></a>
                </li>
                <li class="item">
                  <a
                    href="https://www.behance.net/uvioagency"
                    target="__blank"
                    class="link"
                    ><img
                      src="./../assets/post/behance.svg"
                      alt="Behance"
                      class="img"
                  /></a>
                </li>
              </ul>
              <div class="desc">UVIO LLC © 2023</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  components: {},
  data: function () {
    return {};
  },
  metaInfo: {
    title:
      'UVIO, Carefully devised digital products that strengthen your brand',
    meta: [
      {
        name: 'description',
        content:
          'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users',
      },
      {
        property: 'og:title',
        content:
          'UVIO, Carefully devised digital products that strengthen your brand',
      },
      {
        property: 'og:site_name',
        content:
          'UVIO, Carefully devised digital products that strengthen your brand',
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:description',
        content:
          'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users',
      },
      {
        property: 'og:image',
        content: 'https://www.uvio.co/uvio/share-preview.jpg',
      },
      { property: 'og:url', content: window.location.pathname },
      { name: 'robots', content: 'index,follow' },
      { name: 'twitter:card', content: 'summary_large_image' },
      {
        name: 'twitter:title',
        content:
          'UVIO, Carefully devised digital products that strengthen your brand',
      },
      {
        name: 'twitter:description',
        content:
          'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users',
      },
      {
        name: 'twitter:image',
        content: 'https://www.uvio.co/uvio/share-preview.jpg',
      },
      {
        name: 'twitter:image:alt',
        content:
          'UVIO, Carefully devised digital products that strengthen your brand',
      },
    ],
  },
};
</script>
