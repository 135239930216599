<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'App',

  mounted: function () {
    // var password;
    // var pass1="cool";
    // password=prompt('Please enter your password to view this page!','');
    // if (password!=pass1) {
    //     window.location="https://uvio.agency";
    // }
    $(window).scrollTop(0);
  },
  data: function () {
    return {};
  },
  watch: {},
};
</script>
